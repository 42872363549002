import * as React from 'react';
import { useEffect, useState, useRef, Fragment } from "react"
import { parseHTML } from './../../helpers/parseHTML';
import { isDesktop } from './../../helpers/breakpoints';
import queryString from 'query-string';
import setQueryString from 'set-query-string';

// Components
import Modal from '../global/modal';
import ReactModal from 'react-modal';
import SmartLink from '../global/smart-link';

// ICONS
// @ts-ignore
import Arrow from '../../svg/arrow-right-blue.svg'
// @ts-ignore
import Close from '../../svg/close-x.svg'
// @ts-ignore
import Download from '../../svg/download-icon-blue.svg'
import gtmEvent from '../../helpers/gtmEvent';

interface HomeloansCard {
  loanAmount?: number | string
  expand?: Function
  removeExpands: Function
  ctaText: string
  product: Product
  featureList?: Array<Features>
  repaymentSubtext?: string
  hideCalculation: boolean
  calcError?: string
  isExpanded?: boolean
}

interface Product {
  parentProduct: Parent
  primaryRate: number
  primaryRateTitle: string
  secondaryRate?: number
  secondaryRateTitle?: string
  ongoingFees?: number
  upFrontFees?: number
  variantLabel: string
  variantRateDisclaimer?: string
  variantAuxillaryTitle?: string
  hasCampaignLabel?: boolean
  campaignLabelText?: string
}

interface Parent {
  productTitle: string
  productDescription?: string
  productDisclosure?: string
  featureDisclaimer?: string
  comparisonRateDisclaimer?: string
  features?: Array<Features>
  moreInfo?: moreInfo
  moreInfoText?: string
  repaymentSubtext?: string
}

interface Features {
  featureText?: string
  featureTitle: string
  hasFeatureIcon: boolean
  featureIcon?: {
    url: string
    alt?: string
    title?: string
  }
}

interface moreInfo {
  linkText?: string
  text?: string
  title?: string
}

const CardHomeLoan = ({
  loanAmount,
  product,
  expand,
  removeExpands,
  hideCalculation,
  calcError,
  isExpanded
}: HomeloansCard) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const {
    parentProduct,
    hasCampaignLabel,
    campaignLabelText
  } = product;

  function closeModal(){
    setModalType(null)
    setIsOpen(false)
  }

  function openModal(type = null): string | null {
    
    setModalType(type)
    setIsOpen(true)
    return
  }

  return (
    <>
      <div className={`card-home-loan swiper-slide ${isExpanded ? 'expand' : ''} ${hasCampaignLabel ? 'campaign' : ''} `}>
        <ProductContent
          product={product}
          loanAmount={loanAmount}
          expand={expand}
          removeExpands={removeExpands}
          openModal={openModal}
          modalIsOpen={modalIsOpen}
          hideCalculation={hideCalculation}
          calcError={calcError}
          isExpanded={isExpanded}
        />
      </div>
      {modalIsOpen ?
        <ReactModal
          className={`modal-product home-product ${!modalIsOpen ? 'close' : ''}`}
          overlayClassName={`modal-product__overlay ${!modalIsOpen ? 'close' : ''}`}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel={'Product card information'}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
        >
          <div className="modal-product__content">
            <button
              className="modal-product__close"
              onClick={closeModal}
            >
              <Close />
            </button>
            <p className="modal-product__title">{parentProduct.productTitle}</p>
            {parentProduct.productDisclosure && parentProduct.productDisclosure.length > 0 &&
              <p className="modal-product__disclaimer">{parseHTML(parentProduct.productDisclosure)}</p>
            }
            {product.variantRateDisclaimer && product.variantRateDisclaimer.length > 0 &&
              <p className="modal-product__disclaimer">{parseHTML(product.variantRateDisclaimer)}</p>
            }
            {parentProduct.featureDisclaimer && parentProduct.featureDisclaimer.length > 0 &&
              <p className="modal-product__disclaimer">{parseHTML(parentProduct.featureDisclaimer)}</p>
            }
            {parentProduct.comparisonRateDisclaimer && parentProduct.comparisonRateDisclaimer.length > 0 &&
              <p className="modal-product__disclaimer">{parseHTML(parentProduct.comparisonRateDisclaimer)}</p>
            }
          </div>
        </ReactModal>
      : null}
    </>
  )
}


export default CardHomeLoan;

const ProductContent = ({
  product,
  loanAmount,
  expand,
  removeExpands,
  openModal,
  modalIsOpen,
  hideCalculation,
  calcError,
  isExpanded
}) => {
  const {
    features,
    moreInfo,
    moreInfoText,
    applyNowLink,
    productTitle,
    ctaText,
    termsConditionsText,
    repaymentSubtext
  } = product.parentProduct;

//  console.log(ctaText, moreInfo, moreInfoText, openModal, expand)
    // Check if card has any set of filled out terms
  const hasTerms = 
    product.parentProduct.productDisclosure.length > 0 ||
    product.variantRateDisclaimer.length > 0 ||
    product.parentProduct.featureDisclaimer.length > 0 ||
    product.parentProduct.comparisonRateDisclaimer.length > 0;
    
  return <>
    <section className="card-home-loan__content">    
      {product.hasCampaignLabel && product.campaignLabelText.length > 0 &&
        <p className="card-featured-product__campaign">
          {product.campaignLabelText}
        </p>
      }  
      <h3 className="card-home-loan__title">{productTitle}</h3>
      <ProductRates
        product={product}
      />
      <ProductCalculation
        loanAmount={loanAmount}
        repaymentSubtext={repaymentSubtext}
        calcError={calcError}
        hideCalculation={hideCalculation}
      />
      {features && features.length > 0 ?
        <ProductFeatures
          features={features}
        />
      : null}
      <div className="card-home-loan__content-lower">
        <ProductCta
          expand={expand}
          ctaText={ctaText}
          applyNowLink={applyNowLink}
          moreInfo={moreInfo}
          moreInfoText={moreInfoText}
          openModal={openModal}
          modalIsOpen={modalIsOpen}
          productTitle={productTitle}
          parentProduct={product.parentProduct}
          isExpanded={isExpanded}
        />
      {hasTerms && !isExpanded &&
        <ProductTerms
          parentProduct={product.parentProduct}
          openModal={openModal}
          termsConditionsText={termsConditionsText}
        />
      }
      </div>
    </section>
    {moreInfo ?
      <ProductInfo
        removeExpands={removeExpands}
        moreInfo={moreInfo}
        isExpanded={isExpanded}
        moreInfoText={moreInfoText}
      />
    : null}
  </>
}

const ProductRates = ({ product }) => (
  <section className="card-home-loan__rates">
    <div className="card-home-loan__rates-item">
      <p className="card-home-loan__rates-data">
        <span className="card-home-loan__rates-val">{product.primaryRate}</span>
        <span className="card-home-loan__rates-modifier">p.a.</span>
      </p>
      <p className="card-home-loan__rates-label">{product.primaryRateTitle}</p>
    </div>
    {product.secondaryRate ?
      <div className="card-home-loan__rates-item">
        <p className="card-home-loan__rates-data">
          <span className="card-home-loan__rates-val">{product.secondaryRate}</span>
          <span className="card-home-loan__rates-modifier">p.a.</span>
        </p>
        <p className="card-home-loan__rates-label">{product.secondaryRateTitle}</p>
      </div>
    : null}
  </section>
)

const ProductCalculation = ({ loanAmount, repaymentSubtext, calcError, hideCalculation}) => {
  return <section className={`card-home-loan__repayments ${calcError ? 'error' : ''}${hideCalculation ? ' hidden' : ''}`}>
    {!hideCalculation ?
      <>
        <p>
          <span className="card-home-loan__repayments-val">
            ${loanAmount}
          </span>
          <span className="card-home-loan__repayments-modifier">/mth repayments</span>
        </p>
        {repaymentSubtext ?
          <p className="card-home-loan__repayments-label">{repaymentSubtext}</p>
        : null}
      </>
    : null}
  </section>
}

const ProductFeatures = ({ features }) => (
  <ul className="card-home-loan__features">
    {features.map(({featureText, featureTitle, featureIcon}, index) => (
      <Fragment key={"card-home-loan" + index}>
      {(index + 1) > 8 ?
        null
      :
        <li className="card-home-loan__features-item">
          {featureIcon ?
            <img
              className="card-home-loan__features-icon"
              src={featureIcon.url}
              alt={featureIcon.alt}
              title={featureIcon.title}
            />
          : null}
          <p className="card-home-loan__features-text">
            <strong>{featureTitle}</strong>
            {featureText ? <span>{` ${featureText}`}</span> : null}
          </p>
        </li>
      }
      </Fragment>
    ))}
  </ul>
)

const ProductInfo = ({ removeExpands, moreInfo, moreInfoText }) => {
 return <>
    <section className={`card-home-loan__info`}>
     <div className="card-home-loan__info-header">
       <h3 className="card-home-loan__info-header-title">{moreInfoText ? moreInfoText : `More Info`}</h3>
       {isDesktop() ?
         <button
           className="card-home-loan__info-close"
           onClick={(e) => {
             removeExpands()
             e.target
           }}
           tabIndex={-1}
           style="display:none"
         >
           <Close />
         </button>
       : null}
     </div>
    {moreInfo !== undefined && moreInfo.length > 0 ?
      moreInfo.map((info, index) => {
        if (info.__typename === 'DatoCmsTitleText') {
          return (
            <div
              key={`info-item-${index}`} className="card-home-loan__info-item"
            >
              {info.title &&
                <p className="card-home-loan__info-item-title">
                  {info.title}
                </p>
              }
              {info.text &&
                <p className="card-home-loan__info-item-text">
                  {parseHTML(info.text)}
                </p>
              }
            </div>
          )
        } else if (info.__typename === 'DatoCmsSmartLinkWText') {
          return (
            <div
              key={`info-link-${index}`} className="card-home-loan__info-item link-item"
            >
              {info.text &&
                <p className="card-home-loan__info-item-linktext">
                  {info.text}
                </p>
              }
              <p className="card-home-loan__info-item-link">
                <SmartLink data={info.link}>
                  {info.linkText}
                  <Download />
                </SmartLink >
              </p>
            </div>
          )
        }
      })
    : null}
  </section>
  </>
}

const ProductCta = ({ moreInfo, moreInfoText, expand, openModal, modalIsOpen, applyNowLink, ctaText, productTitle, parentProduct, isExpanded}) => (
  <section className="card-home-loan__cta">
    <SmartLink
      data={applyNowLink}
      className="card-home-loan__cta-apply"
      onClick={() => gtmEvent({
        event: 'applyQuoteCallIntentCTA',
        ctaType: ctaText,
        productType: productTitle,
        productCategory: parentProduct.parentCategory.categoryTitle,
      })}
    >
      {ctaText}
    </SmartLink>
    {Object.keys(moreInfo).length >= 1 && !isExpanded ?
      <button
        className={`card-home-loan__cta-info ${modalIsOpen ? 'open' : ''}`}
        onClick={expand}
      >
        {moreInfoText ? moreInfoText : `More Info`}
        <Arrow />
      </button>
    : null }
  </section>
)

const ProductTerms = ({openModal, termsConditionsText, parentProduct}) => (
  <section className="card-home-loan__terms">
    <p>{termsConditionsText}
      <button
        className="card-home-loan__terms-cta"
        onClick={() => {
          openModal("terms")
          isDesktop() && setQueryString({product: parentProduct.productTitle.replace(/\s+/g, '-')})
        }}
      > - see here
      </button>
    </p>
  </section>
)