interface returnObject {
  convertedRate: number
  hasPercentage: boolean
}

export default function rateHelper(rate: string): returnObject {
  if (rate.includes('%') || rate.includes('pa') || rate.includes('p.a')) {
    return {convertedRate: addZeroes(parseFloat(rate)), hasPercentage: true}
  } else {
    return {convertedRate: addZeroes(parseFloat(rate)), hasPercentage: false}
  }
}

function addZeroes(num) {
  // If not a number, return 0
  if (isNaN(num)) {
    return 0;
  }
  // If there is no decimal, or the decimal is less than 2 digits, toFixed
  if (String(num).split(".").length < 2 || String(num).split(".")[1].length<=2 ){
      num = num.toFixed(2);
  }
  // Return the number
  return num;
}

export function rateTableFormatter(rate: string) {
  return {convertedRate: addZeroes(parseFloat(rate)), hasPercentage: true}
}
