import * as React from 'react';

// Components
import ModuleHeader from './module-header'
import Accordion from './accordion';
import DisclosureBlock from './disclosure-block'
import RatesTable from './rates-table';
import FeesTable from './fees-table';
import queryString from 'query-string';
import { useRef, useEffect } from 'react';
import pluralize from "pluralize";
// Props
interface props {
  accordionId: number
  auxtitle?: string
  title: string
  subtitle?: string
  accordionList?: Array<RatesAccordion>
  disclosure: string
  resourceHeading: string
  resourceList: Array<ResourceLink>
  expandAccordion?: boolean
  removeAccordion?: boolean

}

interface RatesAccordion {
  icon: {
    title?: string
    alt?: string
    url: string
  }
  accordionProducts?: any
  fees?: any
  accordionTitle: string
  feeColumnTitle: string
  hideSelectedVariants?: Array<FilterList>
  hideSelectedSecondaryRate?: Array<FilterList>
  expanded?: boolean
}

interface FilterList {
  id: number
  variantLabel: string
}

interface ResourceLink {
  title: string
  label: string
  pdf: {
    alt?: string
    title?: string
    url: string
  }
  useExternalPdf: boolean
  externalPdfUrl: string
}

const RateAccordionList = ({
  accordionId,
  auxtitle,
  title,
  subtitle,
  accordionList,
  disclosure,
  resourceHeading,
  resourceList,
  expandAccordion,
  removeAccordion
}: props) => {
  let keyword;
  useEffect(() => {
    let { query } = queryString.parse(location.search);
    if (document.location.search.indexOf("?search-term=") > -1 && !query) {
      query = decodeURI(document.location.search.split("=")[1]);
      keyword = query;
      let modkey = pluralize.plural(keyword) === keyword ? pluralize.singular(keyword) : pluralize.plural(keyword);
      accordionList?.forEach((item) => {
        item.expanded = true;
        if (item.fees) {
          //iterate through fees
          //console.log("fees", item.fees, keyword)
          item.fees.forEach((fee) => {
            //console.log("fee", fee, keyword)
            if (fee.category?.match(new RegExp(keyword, "igm")) || fee.title?.match(new RegExp(keyword, "igm")) || fee.category?.match(new RegExp(modkey, "igm")) || fee.title?.match(new RegExp(modkey, "igm"))) {
              item.expanded = true;
            }
          });
          if (!item.expanded) {
            let words = keyword.split(" ");
            words = words.concat(modkey.split(" "));
            words = [...new Set(words)];

            item.fees.forEach((fee) => {
              //console.log("fee", fee, keyword)
              words.forEach((word) => {
                if (fee.category?.match(new RegExp(word, "igm")) || fee.title?.match(new RegExp(word, "igm"))) {
                  item.expanded = true;
                }
              })

            });
          }
        }
        if (item.accordionProducts) {
          //iterate through accordion Products
          //console.log("products", item.accordionProducts, keyword)
          item.accordionProducts.forEach((product) => {

            if (product.productTitle?.match(new RegExp(keyword, "igm")) || product.productDescription?.match(new RegExp(keyword, "igm")) || product.featureDisclaimer?.match(new RegExp(keyword, "igm")) ||
              product.productTitle?.match(new RegExp(modkey, "igm")) || product.productDescription?.match(new RegExp(modkey, "igm")) || product.featureDisclaimer?.match(new RegExp(modkey, "igm"))) {
              item.expanded = true;
            }

            if (product.variants) {
              product.variants.forEach((variant) => {
                if (variant.variantLabel?.match(new RegExp(keyword, "igm")) || product.primaryRateTitle?.match(new RegExp(keyword, "igm")) || product.secondaryRateTitle?.match(new RegExp(keyword, "igm")) ||
                  variant.variantLabel?.match(new RegExp(modkey, "igm")) || product.primaryRateTitle?.match(new RegExp(modkey, "igm")) || product.secondaryRateTitle?.match(new RegExp(modkey, "igm"))) {
                  item.expanded = true;
                }
              })
            }
          });
          if (!item.expanded) {
            let words = keyword.split(" ");
            words = words.concat(modkey.split(" "));
            words = [...new Set(words)];

            item.accordionProducts.forEach((product) => {
              //console.log("fee", fee, keyword)
              words.forEach((word) => {
                if (product.productTitle?.match(new RegExp(word, "igm")) || product.productDescription?.match(new RegExp(word, "igm")) || product.featureDisclaimer?.match(new RegExp(word, "igm"))) {
                  item.expanded = true;
                }
                if (product.variants) {
                  product.variants.forEach((variant) => {
                    if (variant.variantLabel?.match(new RegExp(word, "igm")) || product.primaryRateTitle?.match(new RegExp(word, "igm")) || product.secondaryRateTitle?.match(new RegExp(word, "igm"))) {
                      item.expanded = true;
                    }
                  });
                }
              })

            });
          }
        }
      });
    }
  })
  console.log(expandAccordion, removeAccordion)
  return (
    <section className="rates-accordions">
      <div className="outer-container">
        <div className="inner-container">
          <ModuleHeader
            classNames="rates-accordions__header"
            auxtitle={auxtitle}
            title={title}
            subtitle={subtitle}
          />
          <section className="rates-accordions__list">
            <ul className="accordions">
              {accordionList ? accordionList.map(({
                accordionTitle,
                icon,
                accordionProducts,
                fees,
                feeColumnTitle,
                hideSelectedVariants,
                hideSelectedSecondaryRate,
                expanded
              }, index) => {

                return removeAccordion ? <>
                  {fees ?
                    <FeesTable
                      data={fees}
                      feeColumnTitle={feeColumnTitle}
                    />
                    : accordionProducts ?
                      <RatesTable
                        products={accordionProducts}
                        hideSelectedVariants={hideSelectedVariants}
                        hideSelectedSecondaryRate={hideSelectedSecondaryRate}
                      />
                      : null}
                  {disclosure ?
                    <>
                      <DisclosureBlock
                        subtitle={disclosure}
                        resourceHeading={resourceHeading}
                        resourceList={resourceList}
                      />
                    </>
                    : null}
                </> : <Accordion
                  key={index}
                  index={`rates-${accordionId}-${index}`}
                  title={accordionTitle}
                  icon={icon}
                  openFirst={expanded}
                  expanded={expandAccordion}
                >
                  {fees ?
                    <FeesTable
                      data={fees}
                      feeColumnTitle={feeColumnTitle}
                    />
                    : accordionProducts ?
                      <RatesTable
                        products={accordionProducts}
                        hideSelectedVariants={hideSelectedVariants}
                        hideSelectedSecondaryRate={hideSelectedSecondaryRate}
                      />
                      : null}
                  {disclosure ?
                    <>
                      <DisclosureBlock
                        subtitle={disclosure}
                        resourceHeading={resourceHeading}
                        resourceList={resourceList}
                      />
                    </>
                    : null}
                </Accordion>
              })
                : null}
            </ul>

          </section>

        </div>
      </div>
    </section>
  )
}

export default RateAccordionList

