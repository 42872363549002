import React, { useRef } from 'react'
import { bind } from 'size-sensor';
import gtmEvent from '../../helpers/gtmEvent'
// @ts-ignore
import ArrowDownBlue from '../../svg/arrow-down-blue.svg'
// @ts-ignore
import FaqArrow from '../../svg/chevron-blue-faq.svg'

interface props {
  index: string
  title: string
  faq?: boolean
  openFirst?: boolean
  icon?: {
    title?: string
    alt?: string
    url: string
  }
  expanded?: boolean
}

const Accordion: React.FC<props> = (({title, icon, index, children, faq, openFirst, expanded}) => {
    // build random ID to separate accordions
  let unbind = null;
  const accordionRef = useRef(null);
  const accordionTitleRef = useRef(null);

  const triggerAccordion = (title: string) => {
    gtmEvent({
      'event': 'accordionInteraction',
      'accordionTitle': title
    })

    let transitionPixelsPerSecond = 1000;
    let transitionExtraBuffer = 0.3;
    let accordionTextEl = accordionRef.current;
    let accordionIconEl = accordionTitleRef.current;
    if (accordionTextEl) {
      let elHeight = accordionTextEl.scrollHeight + 60;

      if (accordionTextEl.classList.contains('active')){
        accordionTextEl.style.maxHeight = 0  + "px";
        accordionTextEl.style.transition = `${(elHeight / transitionPixelsPerSecond) + transitionExtraBuffer}s all`;
        accordionTextEl.classList.remove('active');
        accordionIconEl.classList.remove('active');
        if (unbind) unbind()
      } else {
        accordionTextEl.style.transition = `${(elHeight / transitionPixelsPerSecond) + transitionExtraBuffer}s all`;
        accordionTextEl.style.maxHeight = elHeight + "px";
        accordionTextEl.classList.add('active');
        accordionIconEl.classList.add('active');
        unbind = bind(accordionTextEl, () => {
          updateMaxHeight();
        });
      }
    }
  }

  const updateMaxHeight = () => {
    let accordionTextEl = accordionRef.current;
    if (accordionTextEl && accordionTextEl.classList.contains('active')) {
      let elHeight = accordionTextEl.scrollHeight + 60;
      accordionTextEl.style.maxHeight = elHeight + "px";
    }
  }

  if (openFirst && !expanded) {
    setTimeout(() => {
      let accordionTextEl = accordionRef.current;
      if (accordionTextEl && !accordionTextEl.classList.contains('active')) {
        triggerAccordion(title)
      }
    }, 500);
  }else if(expanded){
    setTimeout(() => {
      let accordionTextEl = accordionRef.current;
      if (accordionTextEl && !accordionTextEl.classList.contains('active')) {
        triggerAccordion(title)
      }
    }, 150);
  }
  
  return (
    <li 
      className="accordions__accordion"
      key={`accordion-${index}`}
    >
      <button 
        className="accordions__accordion-title"
        onClick={() => triggerAccordion(title)}
      >
        {icon &&
          <img
            className="accordions__accordion-title-icon"
            src={icon.url}
            alt={icon.alt}
            title={icon.title}
          />
        }
        <span 
          className="accordions__accordion-title-text"
          ref={accordionTitleRef}
        >
          <span>{title}</span>
          {faq ?
            <FaqArrow 
              className={`accordion-icon`}
            />
          :
            <ArrowDownBlue 
              className={`accordion-icon adb`}
            />
          }
        </span>
      </button>
      <div 
        className={`accordions__accordion-text`}
        ref={accordionRef}
      >
        {children}
      </div>
    </li>
  )
})

export default Accordion;
