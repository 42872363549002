import * as React from 'react';
import { Fragment } from 'react';
import { parseHTML } from '../../helpers/parseHTML';
import { rateTableFormatter } from '../../helpers/rateHelper';

// @ts-ignore
import { useTable, useResizeColumns, useFlexLayout } from 'react-table'

// Props
interface props {
  products: any
  hideSelectedVariants: Array<FilterList>
  hideSelectedSecondaryRate: Array<FilterList>
}

interface FilterList {
  id: number
  variantLabel: string
}

const findIndex = (disclaimers, type) => {
  let discIndex;
  disclaimers.forEach(disc => {
    if (disc.disclaimerType === type) {
      discIndex = disc.disclaimerIndex
    }
  });
  return discIndex
}

// This defines the table column header and its accessor used in react-tables
const createColumns = (product, disclaimers, hideSelectedSecondaryRate) => {
  const productDisclaimerIndex = findIndex(disclaimers, "product")

  const detectHiddenRate = (productId) => {
    let isMatch = hideSelectedSecondaryRate.some((variant) => {
      return variant.id === productId ? true : false
    })
    return isMatch
  }

  const productColumnShape = [
    {
      Header: () => {
        return <>
          {product.productColumnTitle || ''}
          {product.productColumnTitle && productDisclaimerIndex ? <sup> ({productDisclaimerIndex})</sup> : null}
        </>
      },
      accessor: 'variantLabel',
      minWidth: 150,
      width: 250,
      maxWidth: 550,
      Cell: ({ cell: { value } }) => {
        return <span className="flex">
          {value}
        </span>
      },
    },
    {
      Header: product.accordionPrimaryRateTitle || "Base Rate",
      accessor: 'primaryRate',
      Cell: ({ cell: { value } }) => {
        const { convertedRate, hasPercentage } = value && rateTableFormatter(value);

        return <span>
          {convertedRate ?
            `${convertedRate}${hasPercentage ? '%' : ''}`
            : ''}
        </span>
      },
      minWidth: 70,
    },
    {
      Header: () => {
        return <>
          {product.accordionSecondaryRateTitle || ''}
        </>
      },
      accessor: 'secondaryRate',
      Cell: ({ cell: { value }, row }) => {
        const { convertedRate, hasPercentage } = value && rateTableFormatter(value);
        return <>
          {!detectHiddenRate(row.original.id) ?
            <span>
              {convertedRate ?
                `${convertedRate}${hasPercentage ? '%' : ''}`
                : ''}
            </span>
            : <span></span>}
        </>
      },
      minWidth: 100,
    },
    // These columns should not be rendered until the
    // BV team revisit requirements of this table
    // {
    //   Header: 'Up Front Fees',
    //   accessor: 'upFrontFees',
    //   Cell: ({cell: {value}}) => {
    //     return `$${value || 0}`
    //   }
    // },
    // {
    //   Header: 'Ongoing Fees',
    //   accessor: 'ongoingFees',
    //   Cell: ({cell: {value}, row}) => {
    //     return `$${value || 0}`
    //   }
    // },
  ]

  return React.useMemo(
    () => [
      {
        Header: product.productTitle,
        columns: productColumnShape
      }
    ],
    []
  )
}


const TableTemplate = ({
  product,
  hideSelectedVariants,
  hideSelectedSecondaryRate
}) => {
  const data = product.variants;
  const productHeaderDisclaimers = [
    {
      disclaimerText: product.productDisclosure,
      disclaimerType: "product"
    }
  ]

  let disclaimers = [];

  productHeaderDisclaimers.forEach((productDisclaimer, index) => {
    productDisclaimer.disclaimerText ?
      disclaimers.push(
        {
          disclaimerText: productDisclaimer.disclaimerText,
          disclaimerIndex: index + 1,
          disclaimerType: productDisclaimer.disclaimerType
        }
      )
      : null
  })

  let currentIndex = disclaimers.length;

  const columns = createColumns(product, disclaimers, hideSelectedSecondaryRate);
  
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 90,
      width: 50
    }),
    []
  )
  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useResizeColumns,
    useFlexLayout,
    

  )
  //console.log(headerGroups)
  //merge headerGroups -- add conditional flag later
  const [h0, h1] = headerGroups;
  h1.headers[0].render = h0.headers[0].render;
  headerGroups = [h1];
  if(h1.headers && h1.headers[2] && h1.headers[2]?.Header().props.children === ''){
    h1.headers.pop();
  }
  const hlen = h1.headers.length;

  const detectHiddenVariant = (productId) => {
    let isMatch = hideSelectedVariants.some((variant) => {
      return variant.id === productId ? true : false
    })
    return isMatch
  }

  return (
    <>
      <section className="rates-table standard" {...getTableProps()}>
          <div className="rates-table__header th">
            {headerGroups.map((headerGroup, key) => (
              <div key={key} className="tr" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <div className="th"{...column.getHeaderProps()}>
                    {column.render('Header')}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="rates-table__body tbody" {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              const rowDisclaimer = data[rowIndex].variantRateDisclaimer;
              if (rowDisclaimer) {
                currentIndex += 1;
                disclaimers.push(
                  {
                    disclaimerText: rowDisclaimer,
                    disclaimerIndex: currentIndex,
                    disclaimerType: "variant"
                  }
                )
              }
              prepareRow(row)
              return <Fragment key={rowIndex}>
                {!detectHiddenVariant(row.original.id) ?
                  <div className="tr" {...row.getRowProps()}>
                    {row.cells.map((cell, columnIndex) => {
                      return columnIndex < hlen ? <div className={`td ${columnIndex}`} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                        {columnIndex <= 0 && rowDisclaimer ?
                          <sup> ({currentIndex})</sup>
                          : null}
                      </div> : null
                    })}
                  </div>
                  : null}
              </Fragment>
            })}
          </div>
      </section>
      {disclaimers.length > 0 ?
        <ol className="rates-table__disclaimers">
          {disclaimers.map(({ disclaimerIndex, disclaimerText }, key) => {
            return disclaimerText && <li key={key}>
              <p>
                {disclaimerIndex && `(${disclaimerIndex}) `}
                {parseHTML(disclaimerText)}
              </p>
            </li>
          })}
        </ol>
        : null}
    </>
  )
}

const RatesTable = ({
  products,
  hideSelectedSecondaryRate,
  hideSelectedVariants
}: props) => {
  return <>
    {products.map((product, key) => {
      return (
        <div className="rates-table__wrapper" key={`rates-table-${key}`}>
          <TableTemplate
            product={product}
            hideSelectedSecondaryRate={hideSelectedSecondaryRate}
            hideSelectedVariants={hideSelectedVariants}
          />
        </div>
      )
    })}
  </>
}

export default RatesTable