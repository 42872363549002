import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import animateScrollTo from 'animated-scroll-to';
import ProductCard from '../cards/card-product-group';
import { parseHTML } from '../../helpers/parseHTML';
import Swiper from 'react-id-swiper'
import ReactModal from 'react-modal';
import queryString from 'query-string';
import setQueryString from 'set-query-string';
// @ts-ignore
import Chevron from '../../svg/chevron-blue-rounded.svg'
// @ts-ignore
import Close from '../../svg/close-x.svg'
import gtmEvent from '../../helpers/gtmEvent';
import { isMobile, isTablet, isTabletDown } from './../../helpers/breakpoints';
import { debounce } from './../../helpers/debounce';

interface props {
  data?: {
    auxtitle: string
    title: string
    information: string
    productCard: any
    filterTitle: string
    preselectedFilter: {
      categoryTitle: string
    }
    filters: [{
      categoryTitle: string
    }]
  }
}

let currentWindowWidth = 0;
let loadEffects = false;

export default function ListingProductGroups({data}: props) {
  let {
    auxtitle,
    title,
    information,
    productCard,
    filterTitle,
    preselectedFilter,
    filters
  } = data;
  
  const listingRef = useRef(null);
  const [slidesPerPage, setSlidesPerPage] = useState(3);
  const [slideIndex, setSlideIndex] = useState(1);
  const [swiperEl, setSwiperEl] = useState(null);
  const [slideWidth, setSlideWidth] = useState(0);
  const [moreInfoIndex, setMoreInfoIndex] = useState(0);
  const initQueryString = typeof window !== `undefined` ? queryString.parse(location.search) : null;
  const [filteredProductCards, setFilteredProductCards] = useState(
    productCard.filter((card) => card.parentProduct.parentCategory.categoryTitle === preselectedFilter.categoryTitle)
  )
  
  const modalRef = useRef(null);
  const [closeModalState, setCloseModalState] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [enableSwiper, setEnableSwiper] = useState(true);


  useEffect(() => {
    if (currentWindowWidth == 0) currentWindowWidth = window.innerWidth;
    
    const debouncedHandleResize = debounce(function handleResize() {
      if (window.innerWidth < 1400 && window.innerWidth !== currentWindowWidth) {
        currentWindowWidth = window.innerWidth;
        setEnableSwiper(false);
        setTimeout(() => {
          setEnableSwiper(true);
          
          if (swiperEl) swiperEl.update();
        }, 100);
      }
    }, 200)
    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)
  })


  function closeModal(){
    setCloseModalState(true)

    setTimeout(() => {
      setCloseModalState(false)
      setIsOpen(false);
    }, 500);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    checkQueryString()
  }, [swiperEl]);

  const checkQueryString = () => {
    if (!loadEffects && swiperEl) {
      loadEffects = true;
    } else if (loadEffects) {
      return
    }

    const parsed = queryString.parse(location.search);
    
    // filter=creditCard&product=productName
    if (parsed.filter) {
      let filter = parsed.filter.replace(/\-+/g, ' ');
      setFilter(filter, true)
      setTimeout(() => animateScrollTo(listingRef.current.offsetTop, {speed: 2000}), 400);
    }

    if (parsed.product) {
      
      setTimeout(() => {
        // TODO: replace dashes to spaces
        let cleanedProduct = parsed.product.replace(/\-+/g, ' ');
        let matchedProductIndices = [];
        for (let i = 0; i < filteredProductCards.length; i++) {
          const card = filteredProductCards[i];
          if (card.parentProduct.productTitle === cleanedProduct) {
            matchedProductIndices.push(i);
          }
        }
        
        setTimeout(() => {
          let cardToHighlight = filteredProductCards[matchedProductIndices[0]];
          // Only expand more info if it has more info data
          if (cardToHighlight && cardToHighlight.parentProduct.moreInfo.length > 0) {
            moreInfoExpand(matchedProductIndices[0])
          }
          highlightCard(matchedProductIndices[0])
        }, 1500);
      }, 1000);
    }
  }

  const setProductQuery = (productTitle, productFilter) => {
    const parsed = queryString.parse(location.search);
    
    setQueryString({
      filter: productFilter ? productFilter.replace(/\s+/g, '-') : parsed.filter ? parsed.filter : null,
      product: productTitle ? productTitle.replace(/\s+/g, '-') : null,
    })
  }

  const setFilter = (filter, dontClear) => {
    // Check if the filter exists in the filter list first
    if (filters.filter(fil => fil.categoryTitle == filter).length > 0) {
      const filterButtons = document.querySelectorAll('.listing-productgroup__filters-button');

      filterButtons.forEach(filterButton => {
        filterButton.classList.remove('active');
        filterButton.classList.remove('highlight');
        if (filterButton.innerHTML == filter) {
          filterButton.classList.add('active');
        }
      });

      // Reset cards
      if (swiperEl) removeExpands()

      setFilteredProductCards(
        productCard.filter(card => card.parentProduct.parentCategory.categoryTitle === filter)
      );

      setTimeout(() => {
        if (swiperEl) {
          swiperEl.update()
          swiperEl.pagination?.update()
          const parsed = queryString.parse(location.search);
          setQueryString({
            filter: filter.replace(/\s+/g, '-'),
            product: dontClear && parsed.product ? parsed.product : null,
          })
        }
      }, 50);
    }
  }

  const highlightCard = (index) => {
    const productCards = document.querySelectorAll('.product-card');
    if (productCards[index]) {
      productCards[index].classList.add('highlight');
    }
  }

  const removeExpands = () => {
    if (swiperEl) {
      let productCards = document.querySelectorAll('.product-card');

      for (let i = 0; i < swiperEl.slides.length; i++) {
        const slide = swiperEl.slides[i];
        slide.style.width = slideWidth + 'px';
        slide.classList.remove('expand')
        slide.classList.remove('highlight')
        swiperEl.update();
      }

      for (let i = 0; i < productCards.length; i++) {
        const card = productCards[i];
        card.classList.remove('highlight')
      }

      if (swiperEl.params.slidesOffsetAfter > 0) {
        swiperEl.params.slidesOffsetAfter = 0;
        swiperEl.update();
      }
    }
  }

  const moreInfoExpand = (key) => {
    let { productTitle } = filteredProductCards[key].parentProduct
    let { categoryTitle } = filteredProductCards[key].parentProduct.parentCategory

    gtmEvent({
      'event': 'moreInfoPopup',
      'productCategory': categoryTitle,
      'productType': productTitle
    })
    
    if (swiperEl && !isTabletDown()) {
      removeExpands();
      let slide = swiperEl.slides[key];
      setProductQuery(productTitle, categoryTitle);
      
      slide.style.transition = 'all 0.4s';
      setTimeout(() => slide.classList.add('expand'), 50);
      if (window.innerWidth > 767) {
        slide.style.width = (slideWidth * 2 + 20 )+ 'px';
      }

      if ((key == swiperEl.slides.length - 1) && swiperEl.slides.length > 2) {
        swiperEl.params.slidesOffsetAfter = slideWidth;
        swiperEl.update();
        if (window.innerWidth > 767) {
          slide.style.width = (slideWidth * 2 + 20 )+ 'px';
        }
      }
      
      swiperEl.slideTo(key, 400);
    } else if (swiperEl && isTabletDown()) {
      setMoreInfoIndex(key)
      setProductQuery(productTitle, categoryTitle);
      setTimeout(() => openModal(), 100);
    }
  }

  const sliderConfig = {
    containerClass: `swiper-container ${filteredProductCards.filter((card) => card.hasCampaignLabel).length > 0 ? 'campaign' : ''}`,
    freeMode: false,
    speed: 600,
    scrollbar: {
      el: '.swiper-scrollbar',
      hide: true
    },
    mousewheel: false,
    a11y: {
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide',
      enabled: true
    },
    on: {
      init: function (this) {
        setSlideWidth(this.slidesSizesGrid[0]);
        setSwiperEl(this)
      },
      slideChange: function () {
        setSlideIndex(this.realIndex + 1);
        if (window.innerWidth > 1024) {
          for (let i = 0; i < this.slides.length; i++) {
            const slide = this.slides[i];
            slide.classList.remove('expand');
            slide.classList.remove('highlight');
          }
        }
      },
      touchMove: function () {
        if (window.innerWidth > 1024) {
          for (let i = 0; i < this.slides.length; i++) {
            const slide = this.slides[i];
            slide.style.width = this.slidesSizesGrid[0] + 'px';
            slide.classList.remove('expand');
            slide.classList.remove('highlight');
          }
        }
      }
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
        centeredSlides: true,
        autoHeight: true,
        pagination: {
          type: 'bullets',
          el: '#filters-pagination-mobile',
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 5,
          renderBullet: function (index, className) {
            return `<button role="button" class=${className}><span class="hidden">navigate to slide ${index}</span></button>`;
          }
        }
      },
    },
    pagination: {
      type: 'custom',
      el: '#filters-pagination',
      renderCustom: function (swiper, currentCard) {
        let swiperAmountOfSlides = swiper.slides.length;
        let amountInView = window.innerWidth >= 768 ? 2 : 1;
        let maxCardsInView = currentCard + amountInView < swiperAmountOfSlides ? currentCard + amountInView : swiperAmountOfSlides;
        return (`
          <span class="pagination-text-bold">Products ${currentCard} to ${maxCardsInView}</span>
          <span>of ${swiperAmountOfSlides}</span>
        `)
      }
    },
    navigation: {
      nextEl: '#filters-next',
      prevEl: '#filters-prev',
    },
    spaceBetween: 20,
    slidesPerView: slidesPerPage,
  }
  
  return (
    <div className={`listing-productgroup`} ref={listingRef}>
      {/* This modal is for mobile moreinfo */}
      <ReactModal
        className={`modal-product ${closeModalState && 'close'}`}
        overlayClassName={`modal-product__overlay ${closeModalState && 'close'}`}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={'Product card information'}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        ref={modalRef}
      >
        <div className="modal-product__content mobile-card">
          <button
            className="modal-product__close"
            onClick={closeModal}
          >
            <Close />
          </button>
          <ProductCard 
            card={filteredProductCards[moreInfoIndex]} 
            isExpanded={true}
          />
        </div>
      </ReactModal>
      {/* This modal is for mobile moreinfo */}
      <div className="outer-container">
        <div className="inner-container">
          <div className={`listing-productgroup__header`}>
            <p className={`listing-productgroup__header-aux`}>{auxtitle}</p>
            <h2 className={`listing-productgroup__header-title`}>{parseHTML(title)}</h2>
            <p className={`listing-productgroup__header-blurb`}>{parseHTML(information)}</p>
          </div>
          <p className="listing-productgroup__filters-title">{filterTitle}</p>
          <div className={`listing-productgroup__filters`}>
            <div className={`listing-productgroup__filters-buttons`} data-datocms-noindex>
              {filters.map(filter => (
                <button 
                  className={`
                    listing-productgroup__filters-button 
                    ${preselectedFilter.categoryTitle === filter.categoryTitle ? 'active' : ''}
                  `} 
                  onClick={() => setFilter(filter.categoryTitle)} 
                  key={filter.categoryTitle}
                >
                  {filter.categoryTitle}
                </button>
              ))}
            </div>
          <div className={`listing-productgroup__filters-pagination ${filteredProductCards.length < 4 ? 'hide' : ''}`}>
          {/* <div className={`listing-productgroup__filters-pagination `}> */}
            <div id="filters-pagination"></div>
            <button 
              className={`pagination-button ${swiperEl && slideIndex <= 1 ? 'inactive' : ''}`} 
              id="filters-prev" 
              onClick={removeExpands}
            >
              <Chevron className={`reverse`}/>
            </button>
            <button 
              className={`pagination-button ${swiperEl && swiperEl.slides && slideIndex == swiperEl.slides.length - 2 ? 'inactive' : ''}`} 
              id="filters-next" 
              onClick={removeExpands}
            >
              <Chevron/>
            </button>
            </div>
          </div>
          <div className={`listing-productgroup__carousel`}>
            {enableSwiper ?
              <Swiper {...sliderConfig}>
                {filteredProductCards.map((card, key) => (
                  <div className="listing-productgroup__carousel-item" key={card.id}>
                    <ProductCard 
                      card={card} 
                      expand={() => moreInfoExpand(key)}
                      close={() => removeExpands()}
                    />
                  </div> 
                ))}
              </Swiper>
            : null}
          </div>
          {filteredProductCards.length > 1}
          <div 
            id="filters-pagination-mobile" 
            className={`${filteredProductCards.length <= 1 && 'hide'}`}>  
          </div>
        </div>
      </div>
    </div>
  )
}