import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import Accordion from './accordion';
import { parseHTML } from './../../helpers/parseHTML';
import SmartLink from '../global/smart-link';
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg';

interface props {
  data?: any
}


export default function RichTextBlock({ data }: props) {
  const {
    auxTitle,
    title,
    richText,
    sectionBodyText,
    linkUnderContent,
    linkText,
    linkUrl
  } = data;

  return (
    <div className={`rich-text`}>
      <div className="outer-container">
        <div className="inner-container">
          {auxTitle &&
            <p className="rich-text__aux">{auxTitle}</p>
          }
          <h2 className="rich-text__title">{title}</h2>
          {sectionBodyText &&
            <p className="rich-text__section-body">{sectionBodyText}</p>
          }
          <div className="rich-text__content rte">
            {parseHTML(richText)}
          </div>
          {linkUnderContent && linkText &&
            <SmartLink data={linkUrl} className="rich-text__under-link">
              {linkText}
              <ArrowRightBlue />
            </SmartLink>
          }
        </div>
      </div>
    </div>
  )
}
