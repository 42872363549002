import * as React from 'react';
import { useEffect, useState, useRef, Fragment } from "react"
// @ts-ignore
import { useTable, useResizeColumns, useFlexLayout } from 'react-table'

// Props
interface props {
  data: any
  feeColumnTitle: string
}

// This defines the table column header and its accessor used in react-tables
const createColumns = (title: string, feeColumnTitle: string) => {
  const columns = [
    {
      Header: feeColumnTitle || "Fee Types",
      accessor: 'title',
      minWidth: 250,
      width: 75,
    },
    {
      Header: 'Fees',
      accessor: 'amount',
      width: 50,
      minWidth: 200,
      Cell: ({cell, row}) => {
        const { label } = row.original || ''
        return `${cell.value}${label ? ` ${label}` : ''}`
      }
    }
  ]
  
  return React.useMemo(
    () => [
      {
        Header: title,
        columns: columns
      }
    ],
    []
  )
}

// This is required to convert the product title to a valid json key to be sorted
const convertToCamelCase = (title) => {
  return title.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+|-/g, '');
}

// This groups the products / fees with the same field listed in sortBy
const sortFeesData = (data) => {
  const sortedData = [];
  
  data.forEach((product) => {
    let key = convertToCamelCase(product.category);
    let keyExists = sortedData.hasOwnProperty(key);

    if (!keyExists) {
      sortedData[key] = [product];
    } else {
      sortedData[key].push(product)
    }
  });
  return sortedData;
}

const TableTemplate = ({data, feeColumnTitle}: props) => {
  const title = data[0].category;
  const columns = createColumns(title, feeColumnTitle);

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 90,
      width: 50
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useResizeColumns,
    useFlexLayout,
  )

  let currentIndex = 0;
  let disclaimers = [];

  return (
    <>
    <section className="rates-table" {...getTableProps()}>
      <div className="rates-table__header th">
        {headerGroups.map(headerGroup => {
          return (
          <div className="tr" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <div className="th"{...column.getHeaderProps()}>{column.render('Header')}</div>
            ))}
          </div>
        )})}
      </div>
      <div className="rates-table__body tbody" {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          const rowDisclaimer = data[rowIndex].feeDisclaimer;
          if (rowDisclaimer) {
            currentIndex += 1;
            disclaimers.push(rowDisclaimer)
          }
          prepareRow(row)
          return (
            <div className="tr" {...row.getRowProps()}>
              {row.cells.map((cell, columnIndex) => {
                return <div className="td" {...cell.getCellProps()}>
                  {cell.render('Cell')}
                  {columnIndex <= 0 && rowDisclaimer  ?
                      <sup> ({currentIndex})</sup>
                  : null}
                </div>
              })}
            </div>
          )
        })}
      </div>
    </section>
    {disclaimers.length > 0 ?
      <ul className="rates-table__disclaimers">
        {disclaimers.map((disclaimer, key) => {
          return <li key={key}><p>({key + 1}) {disclaimer}</p></li>
        })}
      </ul>
    : null}
    </>
  )
}

const FeesTable = ({data, feeColumnTitle}: props) => {
  // Fees are sorted by category
  const sortedFees = sortFeesData(data);
  // This pulls out each key unique key in the table
  const sortedKeys = Object.keys(sortedFees);

  return (
    <>
      {sortedKeys && sortedKeys.map((key, index) => {
        const fees = sortedFees[key];
        return (
          <div
            key={`rate-table-${index}`}
            className="rates-table__wrapper"
          >
            <TableTemplate
              data={fees}
              feeColumnTitle={feeColumnTitle}
            />
          </div>
        )
      })}
    </>
  )
}

export default FeesTable
