import * as React from 'react';
import { FluidObject } from 'gatsby-image';
import Img from "gatsby-image/withIEPolyfill";

// Components
import ModuleHeader from './module-header';

// Props
interface props {
  align: string
  auxtitle?: string
  title: string
  subtitle?: string
  linkText: string
  smartLink: any
  image: {
    url: string
    fluid: FluidObject
    title?: string
    alt?: string
  }
}

const TwoColBlock = ({auxtitle, title, subtitle, linkText, align, image, smartLink}: props) => {

  return (
    <>
      <section className={`two-col-block two-col-block--${align}`}>
        <div className="outer-container">
          <div className="inner-container">
            <section className="two-col-block__col two-col-block__col--image">
              <Img
                fluid={image.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                alt={image.alt}
                title={image.title}
              />
            </section>
            <ModuleHeader
              classNames="two-col-block__col two-col-block__col--header"
              auxtitle={auxtitle}
              title={title}
              subtitle={subtitle}
              smartLink={smartLink}
              linkText={linkText}
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default TwoColBlock
