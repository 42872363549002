import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import ReactModal from 'react-modal';
import SmartLink from '../global/smart-link';
import rateHelper from '../../helpers/rateHelper';
import { parseHTML } from './../../helpers/parseHTML';
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg'
// @ts-ignore
import Close from '../../svg/close-x.svg'
// @ts-ignore
import Call from '../../svg/call-icon.svg'
// @ts-ignore
import Download from '../../svg/download-icon-blue.svg'
import gtmEvent from '../../helpers/gtmEvent';

// Props typescript interface
interface props {
  card: any
  expand?: Function
  close?: Function
  isExpanded?: Boolean
}

export default function ProductGroupCard(props: props) {
  const { card, expand, close, isExpanded } = props;
  const { parentProduct } = card;
  const [closeModalState, setCloseModalState] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);
  
  // Check if card has any set of filled out terms
  const hasTerms =
    parentProduct.productDisclosure.length > 0 ||
    card.variantRateDisclaimer.length > 0 ||
    parentProduct.featureDisclaimer.length > 0 ||
    parentProduct.comparisonRateDisclaimer.length > 0;

  function closeModal(){
    setCloseModalState(true)

    setTimeout(() => {
      setCloseModalState(false)
      setIsOpen(false);
    }, 500);
  }

  function openModal() {
    setIsOpen(true);
  }

  const getRateMarkup = (rate) => {
    if (rate == null) return rate
    const {convertedRate, hasPercentage} = rateHelper(rate);
    if (hasPercentage) {
      return (
        <>
          <p className={`product-card__rates-rate`}>{convertedRate}</p>
          <div className="product-card__rates-postfix">
            <span className={`product-card__rates-percentage`}>%</span>
            <span className={`product-card__rates-pa`}>p.a</span>
          </div>
        </>
      )
    } else {
      return <p className={`product-card__rates-rate`}>{rate}</p>
    }
  }

  return (
    <div className={`product-card ${isExpanded && 'expand'}`}>
      <ReactModal
        className={`modal-product ${closeModalState && 'close'}`}
        overlayClassName={`modal-product__overlay ${closeModalState && 'close'}`}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={'Product card information'}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        ref={modalRef}
      >
        <div className="modal-product__content">
          <button
            className="modal-product__close"
            onClick={closeModal}
          >
            <Close />
          </button>
          <p className="modal-product__title">{parentProduct.productTitle}</p>
          {parentProduct.productDisclosure && parentProduct.productDisclosure.length > 0 &&
            <p className="modal-product__disclaimer">{parseHTML(parentProduct.productDisclosure)}</p>
          }
          {card.variantRateDisclaimer && card.variantRateDisclaimer.length > 0 &&
            <p className="modal-product__disclaimer">{parseHTML(card.variantRateDisclaimer)}</p>
          }
          {parentProduct.featureDisclaimer && parentProduct.featureDisclaimer.length > 0 &&
            <p className="modal-product__disclaimer">{parseHTML(parentProduct.featureDisclaimer)}</p>
          }
          {parentProduct.comparisonRateDisclaimer && parentProduct.comparisonRateDisclaimer.length > 0 &&
            <p className="modal-product__disclaimer">{parseHTML(parentProduct.comparisonRateDisclaimer)}</p>
          }
        </div>
      </ReactModal>
      <div className={`product-card__content`}>
        {card.hasCampaignLabel && card.campaignLabelText.length > 0 &&
          <p className="card-featured-product__campaign">
            {card.campaignLabelText}
          </p>
        }  
        <div className={`product-card__content-top`}>
          {parentProduct.productTitle &&
            <p className={`product-card__title`}>{parentProduct.productTitle}</p>
          }
          {parentProduct.productDescription &&
            <p className={`product-card__description`}>{parentProduct.productDescription}</p>
          }
          {card.variantAuxillaryTitle &&
            <p className={`product-card__variant-aux`}>{card.variantAuxillaryTitle}</p>
          }
          {card.primaryRate &&
            <div className="product-card__rates">
              <div className="product-card__rates-left">
                <div className="product-card__rates-group">
                  {getRateMarkup(card.primaryRate)}
                </div>
                <p className="product-card__rates-aux">{card.primaryRateTitle}</p>
              </div>
              <div className="product-card__rates-right">
                <div className="product-card__rates-group">
                  {getRateMarkup(card.secondaryRate)}
                </div>
                <p className="product-card__rates-aux">{card.secondaryRateTitle}</p>
              </div>
            </div>
          }
          <div className="product-card__details">
            {parentProduct.features !== undefined && parentProduct.features.length > 0 ?
              parentProduct.features.map((feature, index) => (
                <div className="product-card__detail" key={card.id + index}>
                  <p className="product-card__detail-title">{feature.featureTitle}</p>
                  <p className="product-card__detail-text">{parseHTML(feature.featureText)}</p>
                </div>
              ))
            : null}
          </div>
        </div>
        <div className={`product-card__content-bottom`}>
          {parentProduct.hasPhoneNumber && parentProduct.telephoneNumber &&
            <div className="product-card__insurance">
              <p className="product-card__insurance-text">
                <Call/> Call: 
                <a 
                  href={`tel:${parentProduct.telephoneNumber}`}
                  onClick={() => gtmEvent({
                    event: 'applyQuoteCallIntentCTA',
                    ctaType: 'CALL',
                    productType: parentProduct.productTitle,
                    productCategory: parentProduct.parentCategory.categoryTitle,
                  })}
                >
                  {parentProduct.telephoneNumber}
                </a> 
              </p>
            </div>
          }

          <div className="product-card__buttons">
            {parentProduct.applyNowLink && parentProduct.ctaText ? 
              <SmartLink 
                data={parentProduct.applyNowLink} 
                className="product-card__buttons-apply"
                onClick={() => gtmEvent({
                  event: 'applyQuoteCallIntentCTA',
                  ctaType: parentProduct.ctaText,
                  productType: parentProduct.productTitle,
                  productCategory: parentProduct.parentCategory.categoryTitle,
                })}
              >
                {parentProduct.ctaText}
              </SmartLink>
            : null}
            {parentProduct.moreInfo !== undefined && parentProduct.moreInfo.length > 0 && !isExpanded ?
              <button className="product-card__buttons-info" onClick={expand}>
                {parentProduct.moreInfoText ? parentProduct.moreInfoText : `More Info`} <ArrowRightBlue/>
              </button>
            : null}
          </div>
          {hasTerms && !isExpanded &&
            <p className="product-card__terms">
              {parentProduct.termsConditionsText} 
              <a onClick={openModal}> - see here</a>
            </p>
          }
        </div>
      </div>
      <div className={`product-card__info`}>
        <div className={`product-card__info-header`}>
          {parentProduct.moreInfo !== undefined && parentProduct.moreInfo.length > 0 ?
            <p className="product-card__info-title">
              {parentProduct.moreInfoText ? parentProduct.moreInfoText : `More Info`}
            </p>
          : null}
          {!isExpanded &&
            <button className="product-card__info-close" onClick={close}><Close/></button>
          }
        </div>
        <div className={`product-card__info-content`}>
          {parentProduct.moreInfo !== undefined && parentProduct.moreInfo.length > 0 ?
            parentProduct.moreInfo.map((info,index) => {
              if (!info.link) {
                return (
                <div className="product-card__info-item" key={card.id + index}>
                    <p className="product-card__info-item-title">
                      {info.title}
                    </p>
                    <p className="product-card__info-item-text">
                      {parseHTML(info.text)}
                    </p>
                  </div>
                )
              } else if (info.link) {
                return (
                  <div className="product-card__info-item link-item" key={card.id + index}>
                    <p className="product-card__info-item-linktext">
                      {info.text}
                    </p>
                    <p className="product-card__info-item-link">
                      <SmartLink data={info.link}>
                        {info.linkText}
                        <Download/>
                      </SmartLink>
                    </p>
                  </div>
                )
              }
            })
          : null}
        </div>
      </div>
    </div>
  )
}