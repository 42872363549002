import React, { useEffect } from 'react'

//form specific styles only for pages that have this component.
import '../../scss/base/_formatic.scss'
import { isMobile } from '../../helpers/breakpoints';
declare global {
  interface Window {
    Formatic: any;
  }
}

interface IFormData {
  formApiKey: string
  formChannel: string
  formConfigurationSet: string
  formEnvironment: string
  formId: number
  id: string
  name: string
  __typename: string
}

interface IFormModuleProps {
  formData: IFormData
}

const FormModule = ({ formData }: IFormModuleProps) => {
  const formRenderID = formData.name.split(" ").join("-").toLowerCase();

  const showNav = () => {
    const nav = document.querySelector('.nav') as HTMLDivElement;
    if (nav && nav.classList.contains("hide-nav") && !isMobile()) {
      nav.classList.remove("hide-nav")
    }
  }

  useEffect(() => {
    if( typeof window !== undefined){
      setTimeout(() => {
        const Formatic =   window.Formatic
        Formatic.createForm(
          formRenderID,
          formData.formApiKey,
          formData.formId,
          formData.formEnvironment,
          formData.formChannel,
          formData.formConfigurationSet,
          {},
          formData.formEnvironment.toLowerCase() == "development" ? 'https://api-staging.transformd.com/graphql' : 'https://api.transformd.com/graphql',
          formData.formEnvironment.toLowerCase() == "development" ? 'https://api-staging.transformd.com' : 'https://api.transformd.com'
        );
        const scriptTag = document.createElement("script");
        scriptTag.src = "/formatic/formatic-emitter.js";
        scriptTag.async = true;
        document.body.appendChild(scriptTag);
      }, 500)
    }
    
  }, [])

  useEffect(() => {
    if( typeof window !== undefined){
    setTimeout(() => {
      const Formatic =  window.Formatic
      const emitter = Formatic.getDataHelper().getEmitter();
      emitter.on("PageRender", showNav)
      return (() => {
        emitter.off("PageRender", showNav)
      })
    }, 500)
  }
  }, [])

  return (
    <>
      <div className="container">
        <div className="content">
          <div id={formRenderID} />
        </div>
      </div>
    </>
  )
}

export default FormModule
