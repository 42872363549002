import * as React from 'react';
import { parseHTML } from './../../helpers/parseHTML';
import AnimatedLink from '../global/animatedLink';

// ICONS
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg'
import SmartLink from '../global/smart-link';

// Props
interface props {
  auxtitle?: string
  title?: string
  subtitle?: string
  linkText?: string
  classNames?: string
  smartLink?: any
}

const ModuleHeader = ({auxtitle, title, subtitle, linkText, classNames, smartLink }: props) => {
  return (
  <header className={`module-header ${classNames || ""}`}>
    {auxtitle ?
    <p className="module-header__aux">{auxtitle}</p>
    : null}
    {title ? <h2 className="module-header__title">{parseHTML(title)}</h2> : null}
    {subtitle ?
      <div className="module-header__subtitle">{parseHTML(subtitle)}</div>
    : null}
    {smartLink && linkText ?
      <SmartLink className="module-header__cta" data={smartLink}>
        {linkText} <ArrowRightBlue/>
      </SmartLink>
    : null}
  </header>
)}

export default ModuleHeader
