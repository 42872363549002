

import * as React from 'react'
import { useState, useEffect } from 'react'
import { isMobile } from '../../helpers/breakpoints'
import { debounce } from '../../helpers/debounce'
import Swiper from 'react-id-swiper'
import AnimatedLink from '../global/animatedLink'
import { parseHTML } from '../../helpers/parseHTML'
import SmartLink from '../global/smart-link'
import classNames from 'classnames'

// ICONS
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg'

interface FeatureCard {
  data: {
    image?: {
      src: string
      alt?: string
      title?: string
    }
    title: string
    text: string
    richText?: string
    linkText?: string
    smartLink?: any,
    isIcon?:boolean,
    cardClass: string
    hasRte: boolean
  }
}

const FeatureCard = ({data}) => {
  const {linkText, title, text, richText, smartLink, hasRte, isIcon, mode} = data;
  const isImageCard = !linkText && !title && !text && !richText;
  const isLinkCard = smartLink && !hasRte;
  
  
  return isImageCard ?
    <ImageCard
      data={data}
    /> 
    : isLinkCard ?
      <LinkCard
        data={data}
      />
    : 
      <StandardCard
        data={data}
      />
}

const StandardCard = ({data}) => {
  
  return (
    <div className={`${data.cardClass} ${data.isIcon ? 'icon-feature' : ''}`}>
      <FeatureCardContent
        data={data}
      />
    </div>
  )
}

const LinkCard = ({data}) => {
  return (
    <SmartLink
      className={`${data.cardClass} has-link`}
      data={data.smartLink}
    >
      <FeatureCardContent
        data={data}
      />
    </SmartLink>
  )
}

const ImageCard = ({data: {smartLink, image, isIcon}}) => {
  return (
    <>
      {smartLink ?
        <SmartLink
          className="feature-card-image has-link"
          data={smartLink}
        >
          <img className="feature-card-image__graphic" src={image.url} alt={image.alt}/>
        </SmartLink>
      :
        <div className={`feature-card-image ${isIcon ? 'icon' : ''}`}>
          <img className="feature-card-image__graphic" src={image.url} alt={image.alt}/>
        </div>
      }
    </>
  )
}

const FeatureCardContent = ({data: {
  image,
  title,
  text,
  richText,
  linkText,
  smartLink,
  hasRte,
isIcon,  
  mode
}}) => (
  //used to have mode on title linking
  <>
    {image && !isIcon ? <img className={`feature-card__graphic`} src={image.url} alt={image.alt}/> : (image && isIcon ? <div className='img-icon'><img className={`feature-card__graphic icon`} src={image.url} alt={image.alt}/></div> : <div className='empty-spacer'></div>)}
    <div className={`feature-card__content ${mode}`}>
      {title ? ((smartLink && !linkText) || (smartLink && isIcon) ? <SmartLink className="feature-card__content-title" data={smartLink}>{parseHTML(title)} <ArrowRightBlue/> </SmartLink> : <p className="feature-card__content-title">{parseHTML(title)}</p>) : null}
      {text && !hasRte ? <p className="feature-card__content-text">{text}</p> : null}
      {hasRte ?
        <div className="feature-card__content-rte rte">
          {text ? <p>{text}</p> : null}
          {richText ? parseHTML(richText) : null}
        </div>
      : null}
      {linkText && hasRte && !isIcon ?
        <SmartLink className="feature-card__content-cta" data={smartLink}>{linkText} <ArrowRightBlue/> </SmartLink>
        : linkText && !isIcon ?
          <p className="feature-card__content-cta">{linkText} <ArrowRightBlue/></p>
      : null}
    </div>
  </>
)


export default FeatureCard;