import * as React from 'react';
import { useState, Fragment, useRef} from "react"
import { parseHTML } from './../../helpers/parseHTML';
import setQueryString from 'set-query-string';
import queryString from 'query-string';

// Components
import Modal from '../global/modal';
import ReactModal from 'react-modal';
import SmartLink from '../global/smart-link';

// HELPERS
import { isMobile } from '../../helpers/breakpoints'

// ICONS
// @ts-ignore
import Arrow from '../../svg/arrow-right-blue.svg'
// @ts-ignore
import Download from '../../svg/download-icon-blue.svg'
// @ts-ignore
import Close from '../../svg/close-x.svg'
import gtmEvent from './../../helpers/gtmEvent';


const FeaturedProductCard = ({
  product,
  moreInfoExpand,
  isExpanded
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const modalRef = useRef(null);
  const {
    parentProduct: {
      productDisclosure,
      productTitle,
      featureDisclaimer,
      comparisonRateDisclaimer,
      termsConditionsText
    },
    hasCampaignLabel,
    campaignLabelText
  } = product;

  function closeModal(){
    setModalType(null)
    setIsOpen(false);
  }

  function setProductQuery(productTitle) {
    const parsed = queryString.parse(location.search);
    
    setQueryString({
      filter: parsed.filter ? parsed.filter : null,
      product: productTitle ? productTitle.replace(/\s+/g, '-') : null,
    })
  }

  function openModal(type) {
    const parsed = queryString.parse(location.search);
    setModalType(type)
    setIsOpen(true);
    setProductQuery(product.parentProduct.productTitle)

    if (type === 'product') {
      // GTM tag fire
      gtmEvent({
        'event': 'moreInfoPopup',
        'productCategory': product.parentProduct.parentCategory.categoryTitle,
        'productType': product.parentProduct.productTitle
      })
    }
  }

  return (
  <>
    <FeaturedProductContent
      product={product}
      openModal={openModal}
      moreInfoExpand={moreInfoExpand}
      isExpanded={isExpanded || false}
      modalIsOpen={modalIsOpen}
    />
    {modalIsOpen ?
      <ReactModal
        className={`modal-product ${!modalIsOpen && 'close'}`}
        overlayClassName={`modal-product__overlay ${!modalIsOpen && 'close'}`}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={'Product card information'}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        ref={modalRef}
      >
        <div className="modal-product__content">
          <button
            className="modal-product__close"
            onClick={closeModal}
          >
            <Close />
          </button>
          <p className="modal-product__title">{productTitle}</p>
          {productDisclosure && productDisclosure.length > 0 &&
            <p className="modal-product__disclaimer">{parseHTML(productDisclosure)}</p>
          }
          {product.variantRateDisclaimer && product.variantRateDisclaimer.length > 0 &&
            <p className="modal-product__disclaimer">{parseHTML(product.variantRateDisclaimer)}</p>
          }
          {featureDisclaimer && featureDisclaimer.length > 0 &&
            <p className="modal-product__disclaimer">{parseHTML(featureDisclaimer)}</p>
          }
          {comparisonRateDisclaimer && comparisonRateDisclaimer.length > 0 &&
            <p className="modal-product__disclaimer">{parseHTML(comparisonRateDisclaimer)}</p>
          }
        </div>
      </ReactModal>
    : null}
    </>
  )
}

const FeaturedProductContent = ({
  product,
  openModal,
  modalIsOpen,
  moreInfoExpand,
  isExpanded
}) => {
  const {
    features,
    moreInfo,
    moreInfoText,
    details,
    applyNowLink,
    ctaText,
    termsConditionsText,
    productTitle
  } = product.parentProduct;

    // Check if card has any set of filled out terms
  const hasTerms =
    product.parentProduct.productDisclosure.length > 0 ||
    product.variantRateDisclaimer.length > 0 ||
    product.parentProduct.featureDisclaimer.length > 0 ||
    product.parentProduct.comparisonRateDisclaimer.length > 0;

  return (
  <>
    <section className="card-featured-product__content">
      {product.hasCampaignLabel && product.campaignLabelText.length > 0 &&
        <p className="card-featured-product__campaign">
          {product.campaignLabelText}
        </p>
      }
      <h3 className="card-featured-product__title">{productTitle}</h3>
      <section className="card-featured-product__rates">
        <div className="card-featured-product__rates-item">
          <p className="card-featured-product__rates-data">
            <span className="card-featured-product__rates-val">{product.primaryRate}</span>
            <span className="card-featured-product__rates-modifier">p.a.</span>
          </p>
          <p className="card-featured-product__rates-label">{product.primaryRateTitle}</p>
        </div>
        {product.secondaryRate ?
          <div className="card-featured-product__rates-item">
            <p className="card-featured-product__rates-data">
              <span className="card-featured-product__rates-val">{product.secondaryRate}</span>
              <span className="card-featured-product__rates-modifier">p.a.</span>
            </p>
            <p className="card-featured-product__rates-label">{product.secondaryRateTitle}</p>
          </div>
        : null}
      </section>

      {features && features.length > 0 ?
        <ul className="card-featured-product__features">
          {features.map(({featureText, featureTitle, featureIcon}, index) => (
            <Fragment key={"card-feature-product" + index}>
              {(index + 1) > 8 ?
                null
              :
              <li className="card-featured-product__features-item" >
                {featureIcon ?
                  <img
                    className="card-featured-product__features-icon"
                    src={featureIcon.url}
                    alt={featureIcon.alt}
                    title={featureIcon.title}
                  />
                : null}
                <p className="card-featured-product__features-text">
                  <strong>{featureTitle}</strong>
                  {featureText ? <span>{` ${featureText}`}</span> : null}
                </p>
              </li>
              }
            </Fragment>

          ))}
        </ul>
      : null}

      {details !== undefined && details.length > 0 ?
        <ul className="card-featured-product__details">
          {details.map((detail, index) => (
            <li className="card-featured-product__detail" key={`key-${index}`}>
              <p className="card-featured-product__detail-title">{detail.detailTitle}</p>
              <p className="card-featured-product__detail-text">{parseHTML(detail.detailText)}</p>
            </li>
          ))}
        </ul>
      : null}

      <section className="card-featured-product__cta">
        {applyNowLink ?
          <SmartLink 
            data={applyNowLink} 
            className="card-featured-product__cta-apply"
            onClick={() => gtmEvent({
              event: 'applyQuoteCallIntentCTA',
              ctaType: ctaText,
              productType: productTitle,
              productCategory: product.parentProduct.parentCategory.categoryTitle,
            })}
          >
            {ctaText}
          </SmartLink>
        : null}
        {Object.keys(moreInfo).length >= 1 && !isExpanded ?
          <button
            className={`card-featured-product__cta-info ${isExpanded ? 'open' : ''}`}
            onClick={moreInfoExpand}
          >
            {moreInfoText ? moreInfoText : `More info`}
            <Arrow />
          </button>
        : null}
      </section>
      {hasTerms && !isExpanded &&
        <section className={`card-featured-product__terms ${isExpanded ? 'open' : ''}`}>
          <p>{termsConditionsText} 
            <button
              className="card-featured-product__terms-cta"
              onClick={openModal}
            > - see here</button>
          </p>
        </section>
      }
    </section>

    {isExpanded ?
      <section className={`card-featured-product__info`}>
        <div className="card-featured-product__info-header">
          <h3 className="card-featured-product__info-title">{moreInfoText ? moreInfoText : `More info`}</h3>
        </div>
        <div className="card-home-loan__info-content">
          {moreInfo !== undefined && moreInfo.length > 0 ?
            moreInfo.map((info,index) => {
              if (info.__typename == 'DatoCmsTitleText') {
                return (
                  <Fragment key={`info-item-${index}`}>
                  {info.title ?
                    <h4 className="card-featured-product__info-content-title">{info.title}</h4>
                  : null}
                  {info.text ?
                    <p className="card-featured-product__info-content-text">{parseHTML(info.text)}</p>
                  : null}
                  </Fragment>
                )
              } else if (info.__typename == 'DatoCmsSmartLinkWText') {
                return (
                  <p key={`item-link-${index}`} className="card-featured-product__info-content-link">
                    {info.text ?
                      <span>
                        {info.text}
                      </span>
                    : null}
                    <SmartLink data={info.link} >
                      {info.linkText || "View"}
                      <Download />
                    </ SmartLink>
                  </p>
                )
              }
            })
          : null}
        </div>
      </section>
    : null}
  </>
  )
}

export default FeaturedProductCard;