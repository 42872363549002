import * as React from "react";

// ICONS
// @ts-ignore
import DownloadIcon from "../../svg/download-icon-blue.svg";
// @ts-ignore
import PDFIcon from "../../svg/pdf-icon.svg";
import ModuleHeader from "./module-header";
import PdfLink, { IbPdfModel } from "../global/pdf-link";

// Props
interface props {
  auxtitle?: string;
  title?: string;
  subtitle: string;
  resourceHeading?: string;
  resourceList?: Array<ResourceLink>;
}

interface ResourceLink {
  title: string;
  label: string;
  pdf?: {
    alt?: string;
    title?: string;
    url: string;
  };
  ibPdf?: IbPdfModel;
  useExternalPdf: boolean;
  externalPdfUrl?: string;
}

const ResourceLink = ({
  title,
  label,
  useExternalPdf,
  externalPdfUrl = "",
  ibPdf,
  pdf
}: ResourceLink, key) => {
  return (
    <li className="resource-link" key={key}>
      <p className="resource-link__title">{title}</p>
      {ibPdf || useExternalPdf
      ? <PdfLink
          pdfLink={ibPdf}
          externalPdfUrl={externalPdfUrl}
          className="resource-link__download"
          isExternal={useExternalPdf}
          isDownload={true}
          title={title || ibPdf.description}
          label={label}
        >
          <span className="resource-link__download-label">{label}</span>
          <span className="resource-link__download-icon" aria-hidden>
            <DownloadIcon />
          </span>
        </PdfLink>
      : <a 
          className="resource-link__download" 
          aria-label={pdf?.alt || "Download PDF"} 
          href={pdf.url} 
          target="_blank" 
          title={pdf?.title || title} 
          download
        >
          <span className="resource-link__download-label" >
            {label}
          </span>
          <span className="resource-link__download-icon" aria-hidden>
            <DownloadIcon/>
          </span>
        </a>
      }
    </li>
  );
};

const DisclosureBlock = ({ auxtitle, title, subtitle, resourceHeading, resourceList }: props) => {
  return (
    <>
      <section className="disclosure-block">
        <div className="outer-container">
          <div className="inner-container">
            <ModuleHeader
              classNames={`disclosure-block__header ${!resourceHeading ? "disclosure-block__header--full" : ""}`}
              auxtitle={auxtitle}
              title={title}
              subtitle={subtitle}
            />
            {resourceHeading ? (
              <section className="disclosure-block__resource">
                <div className="disclosure-block__resource-title">
                  <PDFIcon />
                  <p>{resourceHeading}</p>
                </div>
                <ul className="disclosure-block__resource-list">
                  {resourceList
                    ? resourceList.map(({ title, label, useExternalPdf, externalPdfUrl, ibPdf, pdf }: ResourceLink, key) => (
                        <ResourceLink
                          key={key}
                          title={title}
                          label={label}
                          ibPdf={ibPdf}
                          pdf={pdf}
                          useExternalPdf={useExternalPdf}
                          externalPdfUrl={externalPdfUrl}
                        />
                      ))
                    : null}
                </ul>
              </section>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
};

export default DisclosureBlock;
