import * as React from 'react'
import { FluidObject, FixedObject } from 'gatsby-image';
import Img from "gatsby-image/withIEPolyfill";

// MODULES
import SmartLink from '../global/smart-link'

// ICONS
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg'
import { parseHTML } from './../../helpers/parseHTML';

interface props {
  image: {
    url: string
    title: string
    alt: string
    fluid: FluidObject
    fixed: FixedObject
  }
  title: string
  text: string
  linkUrl: any
  linkText: string
}

const IconCard = ({
  image,
  title,
  text,
  linkUrl,
  linkText
}: props) => {
  let content = <>
    {image ?
      <div className="icon-card__image">
        {image.fluid ?
          <Img
            className="fluid"
            // fixed={image.fixed}
            fluid={image.fluid}
            objectFit="contain"
            objectPosition="50% 50%"
            alt={image.alt}
          />
        :
          <img className="svg" title={image.title} src={image.url} alt={image.alt}/>
        }
      </div>
    : null}
    {title || text || linkText ?
      <div className="icon-card__content">
        {title ? <p className="icon-card__title">{parseHTML(title)}</p> : null}
        {text ? <p className="icon-card__text">{text}</p> : null}
        {linkText ? <p className="icon-card__link-text">
          <span>{linkText} <ArrowRightBlue/></span>
        </p> : null}
      </div>
    : null}
  </>

  const imageOnly = !title && !text;
  const modifier = imageOnly ? 'image-only' : '';

  return linkUrl ?
    <SmartLink
      data={linkUrl}
      className={`icon-card has-link ${modifier}`}
    >
      {imageOnly ?
        <div className="icon-card__container">
          {content}
        </div>
      : content}
    </SmartLink>
  : 
    <div className={`icon-card ${modifier}`}>
      {imageOnly ?
        <div className="icon-card__container">
          {content}
        </div>
      : content}
    </div>
}

export default IconCard;