// this function replicates a simplified version of excels PMT function
// a full example can be found at:
// https://stackoverflow.com/questions/5294074/pmt-function-in-javascript/22385930#22385930?newreg=53b212355f484b808033c788eaa7e5e0
// This has been updated to use new variable names for readability and presented as a position value

export default function pmtHelper(rate: number, totalMonths:number, loanTotal: number): number {
  /*
    * pmt - paymnet
    * pvif - present value interest factor
  */
  let pmt, pvif;
  const interestRate = rate/12;

  if (interestRate === 0) {
    return (loanTotal)/totalMonths;
  }

  pvif = Math.pow(1 + interestRate, totalMonths);
  pmt = interestRate * loanTotal * (pvif) / (pvif - 1);

  return pmt;
} 