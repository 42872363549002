import * as React from 'react'
import { useState, useEffect } from 'react'
import { isMobile } from '../../helpers/breakpoints'
import { debounce } from '../../helpers/debounce'
import Swiper from 'react-id-swiper'
import { parseHTML } from '../../helpers/parseHTML'
import { FluidObject, FixedObject } from 'gatsby-image';
import { MatchHeight } from 'js-match-height';

// Modules
import ToggleCard from './toggle-card'
import SmartLink from '../global/smart-link'

// ICONS
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg'

// Props

interface props {
  data: {
    auxtitle?: string
    title?: string
    subtitle?: string
    ctaText?: string
    ctaUrl?: any
    primaryToggleText: string
    secondaryToggleText: string
    columnCount: string
    primaryCardList?: Array<ToggleCard>
    secondaryCardList?: Array<ToggleCard>
  }
}

interface ToggleCard {
  image: {
    url: string
    title: string
    alt: string
    fluid: FluidObject
    fixed: FixedObject
  }
  title: string
  text: string
  smartLink: any
}

const ToggleCardList = ({data}: props) => {
  const {
    auxtitle,
    title,
    subtitle,
    ctaText,
    ctaUrl,
    primaryToggleText,
    secondaryToggleText,
    columnCount,
  } = data;

  let isMounted = false;
  const [activeList, setActiveList] = useState({activeCardList: "primaryCardList"})
  const [deviceWidth, setDeviceWidthStatus] = useState({ 
    isMobileDevice: isMobile(),
  })
  const primaryActive = activeList.activeCardList === "primaryCardList"
  const secondaryActive = activeList.activeCardList === "secondaryCardList"

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDeviceWidthStatus({ isMobileDevice: isMobile() })
    }, 500)
    if (!isMounted) isMounted = true;
    if (!isMobile()) {
      const matchHeight = new MatchHeight('.toggle-card');
    }
    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)
  })

  return <section className="toggle-cards">
    <div className="outer-container">
      <div className="inner-container">
        <header className={`toggle-cards__header ${ctaUrl ? 'toggle-cards__header--has-cta' : null}`}>
          <div className="toggle-cards__header-content">
            <p className="toggle-cards__header-aux">{auxtitle}</p>
            <h2 className="toggle-cards__header-title">{parseHTML(title)}</h2>
            {subtitle ? <p className="toggle-cards__header-subtitle">{subtitle}</p> : null}
          </div>
          {!deviceWidth.isMobileDevice && ctaUrl && ctaText ?
            <div className="toggle-cards__cta">
              <SmartLink className="toggle-cards__cta-link" data={ctaUrl}>
                {ctaText} <ArrowRightBlue/>
              </SmartLink>
            </div>
          : null}
          <section className="toggle-cards__toggle">
            <button
              disabled={primaryActive ? true : false}
              className={`toggle-cards__toggle-btn ${primaryActive ? 'active' : ''}`}
              onClick={() => setActiveList({activeCardList: "primaryCardList"})}
            >
              {primaryToggleText}
            </button>
            <span className="toggle-cards__toggle-div" aria-hidden>|</span>
            <button
              disabled={secondaryActive ? true : false}
              className={`toggle-cards__toggle-btn ${secondaryActive ? 'active' : ''}`}
              onClick={() => setActiveList({activeCardList: "secondaryCardList"})}
            >
              {secondaryToggleText}
            </button>
          </section>
        </header>
        {deviceWidth.isMobileDevice ?
          renderCardCarousel(data[activeList.activeCardList], ctaText, ctaUrl)
        :
          renderCardList(data[activeList.activeCardList], columnCount)
        }
      </div>
    </div>
  </section>
}

export default ToggleCardList;

const renderCardCarousel = (toggleCardList, ctaText, ctaUrl) => {
  const carouselClass = "swiper-container toggle-cards__swiper"
  const hasCta = ctaUrl && ctaText;
  console.log(ctaUrl)
  console.log(ctaText)

  const sliderConfig = {
    slidesPerView: 'auto',
    containerClass: carouselClass,
    freeMode: false,
    scrollbar: {
      el: '.swiper-scrollbar',
      hide: true
    },
    mousewheel: false,
    a11y: {
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide',
      enabled: true
    },
    rebuildOnUpdate: true
  }

  return <Swiper {...sliderConfig}>
      {toggleCardList.map((data: ToggleCard, key) =>
        <div className="toggle-cards__swiper-item" key={key}>
          <ToggleCard
            image={data.image}
            title={data.title}
            text={data.text}
            smartLink={data.smartLink}
          />
        </div> 
      )}
      <div
        aria-hidden={!hasCta ? true : false}
        className={`toggle-cards__swiper-item ${!hasCta ? "d-none" : null}`}
      >
        {hasCta ?
          <div className="toggle-cards__swiper-cta">
              <SmartLink className="toggle-cards__cta-link" data={ctaUrl}>
                {ctaText} <ArrowRightBlue/>
              </SmartLink>
          </div>
        : null}
      </div>
  </Swiper>
}

const renderCardList = (toggleCardList, columnCount) =>
  <ul className={`toggle-cards__list toggle-cards__list--${columnCount}`}>
    {toggleCardList.map((data: ToggleCard, key) =>
      <li className="toggle-cards__list-item" key={key}>
        <ToggleCard
          image={data.image}
          title={data.title}
          text={data.text}
          smartLink={data.smartLink}
        />
      </li>
    )}
  </ul>
