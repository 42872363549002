import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import Accordion from './accordion';
import { parseHTML } from './../../helpers/parseHTML';
import SmartLink from './../global/smart-link';
// @ts-ignore
import Download from '../../svg/download-icon-blue.svg'
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg';
import gtmEvent from './../../helpers/gtmEvent';
import PdfLink from '../global/pdf-link';
import queryString from 'query-string';
import pluralize from "pluralize";
interface props {
  data: any
  openFirst?: boolean
  isSearch?: boolean
}


export default function AccordionModule({data, openFirst, isSearch}: props) {
  const {
    auxTitle,
    title,
    sectionBodyText,
    accordionItems,
    linkUnderContent,
    linkText,
    linkUrl
  } = data;
  let keyword;
  useEffect(()=>{
    let { query } = queryString.parse(location.search);
    if(document.location.search.indexOf("?search-term=") > -1 && !query){
      query = decodeURI(document.location.search.split("=")[1]);
      keyword = query;
      let modkey = pluralize.plural(keyword) === keyword ? pluralize.singular(keyword) : pluralize.plural(keyword);
      accordionItems.forEach((item)=>{
        item.content?.forEach((content)=>{
          
          if(content.text?.match(new RegExp(keyword, "igm")) || content.text?.match(new RegExp(modkey, "igm")) || content.fileName?.match(new RegExp(keyword, "igm")) || content.fileName?.match(new RegExp(modkey, "igm"))){
            item.expanded = true;
          }

          
        });
        if(!item.expanded){
          let words = keyword.split(" ");
          words = words.concat(modkey.split(" "));
          words = [...new Set(words)];
          words.forEach((word)=>{            
            item.content?.forEach((content)=>{          
              if(content.text?.match(new RegExp(word, "igm")) || content.fileName?.match(new RegExp(word, "igm"))){
                item.expanded = true;
              }
            });
          });
        }
        
      });
    }
    
    
  })
  return (
    <div className={`accordion-module`}>
      <div className="outer-container">
        <div className="inner-container">
        {auxTitle &&
          <p className="accordion-module__aux">{auxTitle}</p>
      }
        <h2 className="accordion-module__title">{title}</h2>
        {sectionBodyText && !isSearch &&
          <p className="accordion-module__section-body">{sectionBodyText}</p>
        }
        <ul className="accordion-module__accordion">
          {accordionItems.map((item, key) => {
            return (
              <Accordion 
                index={key} 
                title={item.title} 
                key={`key-${key}`}
                faq={true}
                openFirst={item.expanded || (openFirst && key == 0 ? true : false)}
              >
                <div className="accordion-module__accordion-content rte">
                  {item.content.map((content, contentIndex) => {
                    switch (content.__typename) {
                      case 'DatoCmsRichText':
                        return (
                          <div key={contentIndex}>
                            {parseHTML(content.text)}
                          </div>
                        )
                      case 'DatoCmsCtaButton':
                        return (
                          <SmartLink 
                            data={content.linkUrl} 
                            key={`key-${contentIndex}`}
                            className={`accordion-module__accordion-cta not-rte`}
                            onClick={() => gtmEvent({
                              event: 'accordionCTA',
                              accordionTopic: title,
                              accordionTitle: item.title,
                              ctaTitle: content.linkText,
                            })}
                          >
                            {content.linkText}
                          </SmartLink>
                        )
                      case 'DatoCmsFileDownload':
                        return (
                          content.ibPdf
                          ? <PdfLink
                              key={`key-${contentIndex}`}
                              pdfLink={content.ibPdf}
                              externalPdfUrl={content.externalPdfUrl}
                              className="accordion-module__accordion-file not-rte"
                              isExternal={content.useExternalPdf}
                              isDownload={true}
                              title={title || content.ibPdf.description}
                              label=""
                            >
                              <span className="file-name">
                                {content.useExternalPdf ?
                                  `${content.fileName}`
                                : 
                                `${content.fileName} ${content.ibPdf.fileSize}`
                              }
                              </span>
                              DOWNLOAD
                              <Download/>
                            </PdfLink>
                          : <a 
                              href={content.useExternalPdf ? content.externalPdfUrl : content.file?.url}
                              key={`key-${contentIndex}`}
                              className={`accordion-module__accordion-file not-rte`}
                            >
                              <span className="file-name">
                                {content.useExternalPdf ?
                                  `${content.fileName}`
                                : 
                                  `${content.fileName} ${content.file ? `${(content.file.format).toUpperCase()}  ${(content.file.size / 1000).toFixed(2)} KB`: ''}`
                                }
                              </span>
                              DOWNLOAD
                              <Download/>
                            </a>
                          )
                    }
                  })}
                </div>
              </Accordion>
            )
          })}
        </ul>
          {linkUnderContent && linkText && !isSearch &&
            <SmartLink data={linkUrl} className="accordion-module__under-link">
              {linkText}
              <ArrowRightBlue/>
            </SmartLink>
          }
        </div>
      </div>
    </div>
  )
}