import * as React from 'react';
import { useRef, useEffect } from 'react';

// Components
import ModuleHeader from './module-header'
import Accordion from './accordion';
import { parseHTML } from './../../helpers/parseHTML';
import { parse } from 'query-string';
import queryString from 'query-string';
import pluralize from "pluralize";
// Props
interface props {
  accordionId: number
  auxtitle?: string
  title?: string
  subtitle?: string
  accordionList?: Array<InfoAccordion>
}

interface InfoAccordion {
  id: string
  __typename: string
  accordionTitle: string
  icon: {
    title?: string
    alt?: string
    url: string
  }
  content?: string
  embed?: string
  expanded?: boolean
}



const InfoAccordionList = ({
  accordionList,
  auxtitle,
  title,
  subtitle
}: props) => {
  let keyword;
  useEffect(()=>{
    let { query } = queryString.parse(location.search);
    if(document.location.search.indexOf("?search-term=") > -1 && !query){
      query = decodeURI(document.location.search.split("=")[1]);
      keyword = query;
      let modkey = pluralize.plural(keyword) === keyword ? pluralize.singular(keyword) : pluralize.plural(keyword);
      
      accordionList?.forEach((item)=>{        
        if(item.content?.match(new RegExp(keyword, "igm")) || item.content?.match(new RegExp(modkey, "igm"))){
          item.expanded = true;
        }      
        if(!item.expanded){
          let words = keyword.split(" ");
          words = words.concat(modkey.split(" "));
          words = [...new Set(words)];
          words.forEach((word)=>{
            if(item.content?.match(new RegExp(word, "igm"))){
              item.expanded = true;
            }  
          });
        }

      });
    }
    
    
  })
  return (
    <section className="info-accordions">
      <div className="outer-container">
        <div className="inner-container">
          <ModuleHeader
            classNames="info-accordions__header"
            auxtitle={auxtitle}
            title={title}
            subtitle={subtitle}
          />
          <section className="info-accordions__list">
            <ul className="accordions">
              {accordionList ?
                accordionList.map(({
                  __typename,
                  id,
                  accordionTitle,
                  icon,
                  content,
                  embed,
                  expanded
                }, index) => {
                  return <Accordion
                    key={index}
                    index={`accordions__accordion-${id}-${index}`}
                    title={accordionTitle}
                    icon={icon}
                    openFirst={expanded}
                  >
                    {__typename === "DatoCmsInfoAccordion" ?
                      <div className="accordions__accordion-rte rte">
                        {parseHTML(content)}
                      </div>
                    : null}
                    {__typename === "DatoCmsIframeAccordion" ?
                      <div 
                        className="accordions__accordion-iframe"
                      >
                        {parseHTML(embed)}
                      </div>
                    : null}
                  </Accordion>
                })
            : null}
            </ul>
          </section>
        </div>
      </div>
    </section>
  )
}

export default InfoAccordionList