import * as React from 'react'
import { useState, useEffect } from 'react'
import { isMobile } from '../../helpers/breakpoints'
import { debounce } from '../../helpers/debounce'
import Swiper from 'react-id-swiper'
import AnimatedLink from '../global/animatedLink'
import { parseHTML } from '../../helpers/parseHTML'
import SmartLink from '../global/smart-link'
import classNames from 'classnames'
import FeatureCard from './feature-card'

// ICONS
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg'

// Props
interface props {
  auxtitle?: string
  mode?:string
  title?: string
  subtitle?: string
  linkText?: string 
  featureCardList: Array<FeatureCard>
  cardSize: string
  smartLink?: any
  hideTitle?:boolean;
}

const FeatureCardList = ({
  auxtitle,
  mode,
  title,
  subtitle,
  linkText,
  featureCardList,
  cardSize,
  smartLink,
  hideTitle
}: props) => {
  const hasRteCards = featureCardList.some(({richText}) => richText.length > 0)
  const [deviceWidth, setDeviceWidthStatus] = useState({ 
    isMobileDevice: isMobile(),
  })
  
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDeviceWidthStatus({ isMobileDevice: isMobile() })
    }, 500)
    window.addEventListener('resize', debouncedHandleResize)
    
    return () => window.removeEventListener('resize', debouncedHandleResize)
  })

  return <section className={`feature-card-list ${mode} ${hideTitle ? 'hide-title' : ''}`}>
    <div className="outer-container">
      <div className="inner-container">
        <header className="feature-card-list__header">

          <div className="feature-card-list__header-content">
            <p className="feature-card-list__header-aux">{auxtitle}</p>
            <h2 className="feature-card-list__header-title">{parseHTML(title)}</h2>
            {subtitle ? <p className="feature-card-list__header-subtitle">{subtitle}</p> : null}
          </div>

          {!deviceWidth.isMobileDevice && smartLink ?
            <div className="feature-card-list__header-cta">
              {smartLink &&
                <SmartLink className="feature-card-list__header-link" data={smartLink}>
                  {linkText} <ArrowRightBlue/>
                </SmartLink>
              }
            </div>
          : null}
        </header>
        {deviceWidth.isMobileDevice ?
          renderCardCarousel(featureCardList, cardSize, linkText, smartLink, hasRteCards, mode)
        :
          renderCardList(featureCardList, cardSize, hasRteCards, mode)
        }
      </div>
    </div>
  </section>
}

export default FeatureCardList;

const renderCardCarousel = (featureCardList, cardSize, linkText, smartLink, hasRte, mode) => {
  const carouselClass = `swiper-container feature-card-list__swiper feature-card-list__swiper--${cardSize}`
  const modifier = hasRte ? 'rte' : cardSize
  const cardClass = classNames(`feature-card feature-card--${modifier}`, {
    'feature-card--reverse': cardSize === "large" || hasRte,
  })

  const sliderConfig = {
    slidesPerView: 'auto',
    containerClass: carouselClass,
    freeMode: false,
    speed: 600,
    scrollbar: {
      el: '.swiper-scrollbar',
      hide: true
    },
    mousewheel: false,
    a11y: {
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide',
      enabled: true
    },
    rebuildOnUpdate: true
  }

  return <Swiper {...sliderConfig}>
    {featureCardList.map((data: FeatureCard, key) =>
      <div className="feature-card-list__swiper-item" key={key}>
        <FeatureCard
          data={{mode:mode, cardClass: cardClass, hasRte: hasRte, ...data}}
        />
      </div> 
    )}
    <div className={`feature-card-list__swiper-item ${!smartLink && "d-none"}`}>
      {smartLink ?
        <div className="feature-card-list__swiper-cta">
          <SmartLink className="feature-card-list__header-link" data={smartLink}>
            {linkText} <ArrowRightBlue/>
          </SmartLink>
        </div>
      : null}
    </div>
  </Swiper>
}

const renderCardList = (featureCardList, cardSize, hasRte, mode) => {
  const modifier = hasRte ? 'rte' : cardSize
  const cardClass = classNames(`feature-card feature-card--${modifier}`, {
    'feature-card--reverse': cardSize === "large" || hasRte,
  })

  return <ul className={`feature-card-list__list feature-card-list__list--${modifier}`}>
    {featureCardList.map((data: FeatureCard, key) =>
      <li className={`feature-card-list__list-item ${data.data?.isIcon ? 'is-icon' : ''}`} key={key}>
        <FeatureCard
          data={{mode:mode, cardClass: cardClass, hasRte: hasRte, ...data}}
        />
      </li>
    )}
  </ul>
}