import * as React from 'react';
import { useEffect, useState, useRef } from "react"
import { debounce } from "../../helpers/debounce";
import { FluidObject } from 'gatsby-image';
import ModuleHeader from './module-header';
import Img from "gatsby-image/withIEPolyfill";

// Props
interface props {
  auxtitle?: string
  title: string
  subtitle?: string
  image: {
    url: string
    fluid: FluidObject
    alt?: string
    title: string
  }
  steps: Array<Step>
}

interface Step {
  index: number
  title: string
  subtitle: string
  icon: {
    url: string
    title?: string
    alt?: string
  }
}

const Step = ({title, subtitle, icon, index}: Step) => (
  <li key={index} className="step">
    <div className="step__icon">
      <img className="step__icon-graphic" src={icon.url} alt={icon.alt} title={icon.title}/>
    </div>
    <div className="step__content">
      <h3 className="step__content-title">{title}</h3>
      <p className="step__content-text">{subtitle}</p>
    </div>
  </li>
)

const StepGuide = ({auxtitle, title, subtitle, image, steps}: props) => {
  const stepGuideRef = useRef(null);
  const [stepGuideOffset, setstepGuideOffset] = useState(0);
  const [stepGuideHeight, setstepGuideHeight] = useState(0);
  const [isStepGuideEnd, setStepGuideEnd] = useState(false);
  let stepGuideScrollEvent = null;
  let stepGuideMounted = false;

  useEffect(() => {
    let stepGuide = stepGuideRef.current;

    if (!stepGuideMounted && !isStepGuideEnd) {
      setstepGuideOffset(stepGuide.getBoundingClientRect().top)
      setstepGuideHeight(stepGuide.getBoundingClientRect().height)
      setStepScrollHandler()
      stepGuideMounted = true;
    }
    return () => {
      document.removeEventListener('scroll', stepGuideScrollEvent)
    };
  });

  const setStepScrollHandler = () => {
    document.addEventListener('scroll', stepGuideScrollEvent = debounce((e) => {
      handleStepsOnScroll(e)
    }, 25)
    , false)
  }

  const handleStepsOnScroll = (e) => {
    if (stepGuideRef.current) {
      setstepGuideOffset(stepGuideRef.current.getBoundingClientRect().top)
      const activePoint = window.innerHeight / 2;
      const stepNodes = stepGuideRef.current.querySelectorAll('.step');

      if (stepGuideOffset - activePoint < 0 && stepGuideOffset + stepGuideHeight - activePoint > 0) {
        for (let i = 0; i <= stepNodes.length - 1; i += 1) {
          handleStepActiveState(stepNodes[i], activePoint)
        }
      } else if (stepGuideOffset + stepGuideHeight - activePoint < 0) {
        setStepGuideEnd(true)
      }
    }
  }

  const handleStepActiveState = (step, activePoint) => {
    let stepPos = step.getBoundingClientRect().top
    let stepHeight = step.getBoundingClientRect().height

    if (stepPos - activePoint < 0 && stepPos + stepHeight - activePoint > 0) {
      step.classList.add('active')
    } else if (stepPos - activePoint > 0 || stepPos + stepHeight - activePoint < 0) {
      step.classList.remove('active')
    }
  }
  //console.log("img", image)
  return (
    <section className="step-guide">
      <div className="outer-container">
        <div className="inner-container">
          <section className="step-guide__content">
            <ModuleHeader
              classNames="step-guide__content-header"
              auxtitle={auxtitle}
              title={title}
              subtitle={subtitle}
            />
            <div className="step-guide__content-media">
              {image.fluid ? 
                <Img
                  fluid={image.fluid}
                  objectFit="contain"
                  objectPosition="50% 0"
                  alt={image.alt}
                  title={image.title}
                />
              :
                <img className="step-guide__content-media-img" src={image.url} alt={image.alt}/>
              }
            </div>
          </section>
          <section className="step-guide__steps">
            <ol className="step-guide__steps-list" ref={stepGuideRef}>
              {steps.map(({title, subtitle, icon}: Step, index) => {
                return <Step
                  key={index}
                  index={index}
                  title={title}
                  subtitle={subtitle}
                  icon={icon}
                />
              })}
            </ol>
          </section>
        </div>
      </div>
    </section>
  )
}

export default StepGuide
