import * as React from 'react'

import SmartLink from '../global/smart-link'
import { FluidObject, FixedObject } from 'gatsby-image';
import Img from "gatsby-image/withIEPolyfill";

interface props {
  image: {
    url: string
    title: string
    alt: string
    fluid: FluidObject
    fixed: FixedObject
  }
  title: string
  text: string
  smartLink: any
}

const ToggleCard = ({
  image,
  title,
  text,
  smartLink
}: props) => {
  let content = <>
    <div className="toggle-card__image">
      {image.fluid ?
        <Img
          fixed={image.fixed}
          objectFit="contain"
          objectPosition="50% 50%"
          alt={image.alt}
        />
      :
        <img className="svg" title={image.title} src={image.url} alt={image.alt}/>
      }
    </div>
    {title || text ?
      <div className="toggle-card__content">
        {title ? <p className="toggle-card__title">{title}</p> : null}
        {text ? <p className="toggle-card__text">{text}</p> : null}
      </div>
    : null}
  </>

  const modifier = !title && !text ? 'image-only' : '';

  return smartLink ?
    <SmartLink
      data={smartLink}
      className={`toggle-card has-link ${modifier}`}
    >
      {content}
    </SmartLink>
  : <div 
      className={`toggle-card has-link ${modifier}`}
    >
    {content}
  </div>
}

export default ToggleCard;