import FeatureCardList from '../modules/feature-card-list'
import FeaturedContentHub from '../modules/content-hub'
import FeaturedProductBlock from "../modules/product-block"
import TwoColBlock from '../modules/two-col-block'
import React, { Component } from "react"
import DisclosureBlock from '../modules/disclosure-block'
import RateAccordionList from '../modules/rate-accordion-list'
import InfoAccordion from '../modules/info-accordion-list'
import StepGuide from '../modules/step-guide'
import ListingHomeLoan from '../listings/listing-home-loan'
import ListingProductGroups from '../listings/listing-product-groups'
import ListingFeaturedProduct from '../listings/listing-featured-product'
import AccordionModule from './../modules/accordion-module';
import RichTextBlock from '../modules/rich-text-block'
import ToggleCardList from '../campaign/toggle-card-list';
import IconCardList from '../modules/icon-card-list'
import FormModule from '../modules/form-module'


const PageBody = ({ components }) => {
  
  let bodyHTML = [];
  
  for (let section = 0; section < components.length; section++) {
    const component = components[section];
    let bodyMarkup;
    if (component !== null) {
      //console.log(component)
      let typename = component.__typename || component.id?.split("-")[0]; 
      
      if(!typename && "stickyNavId" in component){
        typename = "DatoCmsStickyNavSection";
      }
      
      switch (typename) {
        case 'DatoCmsProductFeatureBlock':
          bodyMarkup = (
            <FeaturedProductBlock
              key={section}
              align={component.align}
              auxtitle={component.auxtitle}
              title={component.title}
              subtitle={component.subtitle}
              smartLink={component.smartLink}
              linkText={component.linkText}
              productLinkList={component.productLinkList}
              cardSize={component.cardSize}
              alignment={component.alignment}
            />
          )
          break;
        case 'DatoCmsFeatureCardList':
          
          bodyMarkup = (
            <FeatureCardList
              key={section}
              mode={component.mode}
              cardSize={component.cardSize}
              auxtitle={component.auxtitle}
              title={component.title}
              subtitle={component.subtitle}
              smartLink={component.smartLink}
              linkText={component.linkText}
              hideTitle={component.hideTitle}
              featureCardList={component.featureCardList}
            />
          )
          break;
        case 'DatoCmsFeaturedContentHub':
          bodyMarkup = (
            <FeaturedContentHub
              key={section}
              auxtitle={component.auxtitle}
              title={component.title}
              subtitle={component.subtitle}
              smartLink={component.smartLink}
              linkText={component.linkText}
              articleLinkList={component.articleLinkList}
            />
          )
          break;
        case 'DatoCmsTwoColBlock':
          bodyMarkup = (
            <TwoColBlock
              key={section}
              auxtitle={component.auxtitle}
              title={component.title}
              subtitle={component.subtitle}
              smartLink={component.smartLink}
              linkText={component.linkText}
              align={component.align}
              image={component.image}
            />
          )
          break;
        case 'DatoCmsDisclosureBlock':
          bodyMarkup = (
            <DisclosureBlock
              key={section}
              auxtitle={component.auxtitle}
              title={component.title}
              subtitle={component.subtitle}
              resourceHeading={component.resourceHeading}
              resourceList={component.resourceList}
            />
          )
          break;
        case 'DatoCmsStickyNavSection':
          bodyMarkup = (
            <a className={`sticky-nav-wedge`} id={component.stickyNavId} key={section} data-datocms-noindex></a>
          )
          break;
        case 'DatoCmsStepGuide':
          bodyMarkup = (
            <StepGuide
              key={section}
              auxtitle={component.auxtitle}
              title={component.title}
              subtitle={component.subtitle}
              image={component.image}
              steps={component.steps}
            />
          )
          break;
        case 'DatoCmsListingHomeLoan':
          bodyMarkup = (
            <ListingHomeLoan
              key={section}
              auxtitle={component.auxtitle}
              title={component.title}
              subtitle={component.information}
              information={component.information}
              defaultLoanAmount={component.defaultLoanAmount}
              minLoanAmount={component.minLoanAmount}
              maxLoanAmount={component.maxLoanAmount}
              productCard={component.productCard}
              hideCalculationOnVariants={component.hideCalculationOnVariants}
            />
          )
          break;
        case 'DatoCmsListingProductGroup':
          bodyMarkup = (
            <ListingProductGroups
              key={section}
              data={component}
            />
          )
          break;
        case 'DatoCmsListingFeaturedProduct':
          bodyMarkup = (
            <ListingFeaturedProduct
              key={section}
              auxtitle={component.auxtitle}
              title={component.title}
              subtitle={component.information}
              information={component.information}
              productCard={component.productCard}
              filterTitle={component.filterTitle}
              preselectedFilter={component.preselectedFilter}
              filters={component.filters}
            />
          )
          break;
        case 'DatoCmsRatesAccordionList':
          bodyMarkup = (
            <RateAccordionList
              key={section}
              auxtitle={component.auxtitle}
              title={component.title}
              subtitle={component.subtitle}
              disclosure={component.disclosure}
              resourceHeading={component.resourceHeading}
              resourceList={component.resourceList}
              accordionId={component.id}
              accordionList={component.accordionList}
              expandAccordion={component.expandAccordion}
              removeAccordion={component.removeAccordion}
            />
          )
          break;
        case 'DatoCmsAccordionModule':
          bodyMarkup = (
            <AccordionModule
              key={section}
              data={component}
            />
          )
          break;
        case 'DatoCmsRichTextBlock':
          bodyMarkup = (
            <RichTextBlock
              key={section}
              data={component}
            />
          )
          break;
        case 'DatoCmsInfoAccordionList':
          bodyMarkup = (
            <InfoAccordion
              key={section}
              auxtitle={component.auxtitle}
              title={component.title}
              subtitle={component.subtitle}
              accordionId={component.id}
              accordionList={component.accordionList}
            />
          )
          break;
        case 'DatoCmsToggleCardList':
          bodyMarkup = (
            <ToggleCardList
              key={section}
              data={component}
            />
          )
          break;
        case 'DatoCmsIconCardList':
          bodyMarkup = (
            <IconCardList
              key={section}
              data={component}
            />
          )
          break;
        case 'DatoCmsForm':
          bodyMarkup = (
            <FormModule key={section} formData={component} />
          )
        default:
          break;
      }
      bodyHTML.push(bodyMarkup)
    }
  }
  return <>{bodyHTML}</>;
}

export default PageBody;
