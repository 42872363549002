import * as React from 'react';
import { useEffect, useState, useRef } from "react"
import Swiper from 'react-id-swiper'
import formatCurrencyInput from 'format-currency-input'
import queryString from 'query-string';
import setQueryString from 'set-query-string';
import animateScrollTo from 'animated-scroll-to';

// HELPERS
import { isMobile, isTablet, isDesktop, isDesktopPro } from '../../helpers/breakpoints'
import pmtHelper from '../../helpers/pmtHelper'

// ICONS
// @ts-ignore
import Chevron from '../../svg/chevron-blue-rounded.svg'
// @ts-ignore
import Close from '../../svg/close-x.svg'

// Components
import ModuleHeader from '../modules/module-header';
import HomeLoanCard from '../cards/card-home-loan';
import gtmEvent from '../../helpers/gtmEvent';
import ReactModal from 'react-modal';
import { isTabletDown } from './../../helpers/breakpoints';
import { debounce } from './../../helpers/debounce';
import { v4 as uuidv4 } from 'uuid';
// Props
interface props {
  auxtitle?: string
  title: string
  information?: string
  subtitle?: string
  productCard: any
  defaultLoanAmount: string
  minLoanAmount: string
  maxLoanAmount: string
  hideCalculationOnVariants?: Array<HideList>
}

interface HideList {
  id: string
}

let currentWindowWidth = 0;
let loadEffects = false;

const ListingHomeLoan = ({
  auxtitle,
  title,
  subtitle,
  productCard,
  defaultLoanAmount,
  minLoanAmount,
  maxLoanAmount,
  hideCalculationOnVariants
}: props) => {
  let listingMounted = false;
  const listingRef = useRef(null);
  const loanInput = useRef(null)
  const [loanAmount, setLoanAmount] = useState(defaultLoanAmount);
  const [calcError, setCalcError] = useState(null);
  const [swiper, setSwiper] = useState(null);
  const [slideWidth, setSlideWidth] = useState(0);
  const [slideIndex, setSlideIndex] = useState(1);
  const modalRef = useRef(null);
  const [closeModalState, setCloseModalState] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [moreInfoIndex, setMoreInfoIndex] = useState(0);
  const [enableSwiper, setEnableSwiper] = useState(true);
  const currentUid = uuidv4();

  useEffect(() => {
    if (currentWindowWidth == 0) currentWindowWidth = window.innerWidth;

    const debouncedHandleResize = debounce(function handleResize() {
      if (window.innerWidth < 1400 && window.innerWidth !== currentWindowWidth) {
        currentWindowWidth = window.innerWidth;
        setEnableSwiper(false);

        setTimeout(() => {
          setEnableSwiper(true);
          if (swiper) swiper.update();
        }, 10);
      }
    }, 100)
    window.addEventListener('resize', debouncedHandleResize)

    return () => window.removeEventListener('resize', debouncedHandleResize)
  })

  const checkQueryString = () => {
    if (!loadEffects && swiper) {
      loadEffects = true;
    } else if (loadEffects) {
      return
    }

    const parsed = queryString.parse(location.search);

    if (parsed && parsed.product && swiper) {
      let productCards = document.querySelectorAll('.card-home-loan.swiper-slide');
      let cleanedProduct = parsed.product.replace(/\-+/g, ' ');
      let matchedProductIndices = [];
      for (let i = 0; i < productCard.length; i++) {
        const card = productCard[i];
        if (card.parentProduct.productTitle === cleanedProduct) {
          matchedProductIndices.push(i);
        }
      }

      setTimeout(() => {
        let cardToHighlight = productCard[matchedProductIndices[0]]

        if (swiper && cardToHighlight && cardToHighlight.parentProduct.moreInfo.length > 0) {
          expand(matchedProductIndices[0], cardToHighlight)
        }

        setTimeout(() => {
          productCards[matchedProductIndices[0]].classList.add('highlight')
        }, 1500);
      }, 400);
    }
  }

  const setProductQuery = (productTitle) => {
    setQueryString({
      product: productTitle ? productTitle.replace(/\s+/g, '-') : null,
    })
  }

  const closeModal = () => {
    setCloseModalState(true)

    setTimeout(() => {
      setCloseModalState(false)
      setIsOpen(false);
    }, 500);
  }

  const openModal = () => {
    setIsOpen(true);
  }

  const sliderConfig = {
    slidesPerView: 'auto',
    containerClass: `carousel-${currentUid} ${productCard.filter((card) => card.hasCampaignLabel).length > 0 ? 'campaign' : ''}`,
    spaceBetween: 30,
    mousewheel: false,
    speed: 600,
    navigation: {
      nextEl: `#filters-next-${currentUid}`,
      prevEl: `#filters-prev-${currentUid}`,
    },
    pagination: {
      type: 'custom',
      el: `#filters-pagination-${currentUid}`,
      renderCustom: function (swiper, currentCard) {
        
        let swiperAmountOfSlides = swiper.slides.length;
        let maxCardsInView = currentCard + 1 < swiperAmountOfSlides ? currentCard + 1 : swiperAmountOfSlides;
        return (`
          <span>Products ${currentCard} to ${maxCardsInView}</span>
          <span>of ${swiperAmountOfSlides}</span>
        `)
      }
    },
    on: {
      init: function () {
        setSlideWidth(this.slidesSizesGrid[0]);
      },
      touchMove: function (this) {
        if (isDesktopPro()) {
          for (let i = 0; i < this.slides.length; i++) {
            const slide = this.slides[i];
            slide.style.width = this.slidesSizesGrid[0] + 'px';
            slide.classList.remove('card-home-loan--open')
            addTabIndex(slide, - 1)
          }
          this.params.slidesOffsetAfter = 0;
          this.update();
        }
      },
      // reachEnd: function () {
      //   setSlideIndex(this.slides.length);
      // },
      slideChange: function () {
        setSlideIndex(this.activeIndex + 1);
      },
    },
    a11y: {
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide',
      enabled: true
    },
    breakpoints: {
      1201: {
        spaceBetween: 30,
        slidesPerView: "auto",
        // slidesOffsetAfter: 605
      },
      1024: {
        spaceBetween: 30,
        slidesPerView: "auto",
        // slidesOffsetAfter: 530,
      },
      768: {
        spaceBetween: 10,
        slidesPerView: 2,
        // slidesPerColumn: 1,
        // centeredSlides: true,
        pagination: {
          type: 'bullets',
          el: `#filters-pagination-${currentUid}`,
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 5,
          renderBullet: function (index, className) {
            return `<button role="button" class=${className}><span class="hidden">navigate to slide ${index}</span></button>`;
          }
        }
      },
      320: {
        spaceBetween: 15,
        slidesPerColumn: 1,
        centeredSlides: true,
        pagination: {
          type: 'bullets',
          el: `#filters-pagination-${currentUid}`,
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 5,
          renderBullet: function (index, className) {
            return `<button role="button" class=${className}><span class="hidden">navigate to slide ${index}</span></button>`;
          }
        }
      }
    }
  }

  const formatCurrency = (val) => {
    return formatCurrencyInput.format(val, { removeCents: true });
  }

  const removeCurrencyFormat = (val) => {
    return formatCurrencyInput.format(val, {
      removeCents: true,
      removeSymbol: true,
      removeCommas: true,
    });
  }

  const formatInputAmount = (val) => {
    loanInput.current.value = formatCurrencyInput.format(val, { removeCents: true });
  }

  const checkInputDefault = (val) => {
    let parsedVal = parseInt(removeCurrencyFormat(val));
    if (parsedVal < 15000 || parsedVal > 5000000) {
      loanInput.current.value = '$200,000';
      setLoanAmount(loanInput.current.value)
      handleLoanErrorMessage(loanInput.current.value)
      formatInputAmount(loanInput.current.value)
    }
  }

  const handleLoanErrorMessage = (val) => {
    val = removeCurrencyFormat(val);
    const hasInvalidVal = val.match(/[a-zA-Z]|[^\w\s]+/)
    const loan = hasInvalidVal ? null : parseFloat(val)
    const loanError = hasInvalidVal || loan < parseFloat(minLoanAmount) || loan > parseFloat(maxLoanAmount)
    const errorMessage = loanError ?
      val.match(/[a-zA-Z]/) ? 'Loan amount must be a number'
        : `Loan amount must be between ${formatCurrency(cleanUpLoanOutput(minLoanAmount))} and ${formatCurrency(cleanUpLoanOutput(maxLoanAmount))}`
      : ''
    setCalcError(errorMessage)
  }

  const cleanUpLoanOutput = (loan) => {
    let loanOutput = loan.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    return loanOutput ? loanOutput : null
  }

  const calculateLoan = (loanAmount, rate) => {
    if (!calcError) {
      loanAmount = removeCurrencyFormat(loanAmount);
      const loan = parseFloat(loanAmount)
      const rateAsDecimal = rate / 100
      const defaultVal = parseFloat(defaultLoanAmount)
      const years = 30
      const months = 12
      const totalRepayments = pmtHelper(rateAsDecimal, (years * months), loan)
        || pmtHelper(rateAsDecimal, (years * months), defaultVal)

      const repaymentsPerMonth = Math.round(totalRepayments)

      // formats the repayments for display
      return cleanUpLoanOutput(repaymentsPerMonth)
    }
    return null
  }

  const removeExpands = () => {
    console.log(swiper)
    for (let i = 0; i < swiper.slides.length; i++) {
      const slide = swiper.slides[i];
      slide.style.width = slideWidth + 'px';
      slide.classList.remove('card-home-loan--open')
      slide.classList.remove('highlight')

      addTabIndex(slide, - 1)
    }
    swiper.params.slidesOffsetAfter = 0;
    swiper.update();
  }

  const addTabIndex = (slide, index = 0) => {
    const moreInfo = slide.querySelector('.card-home-loan__info');
    moreInfo.querySelectorAll('a').forEach(link => {
      link.setAttribute('tabindex', index)
    });
    moreInfo.querySelectorAll('button').forEach(button => {
      button.setAttribute('tabindex', index)
    });
  }

  const expand = (key, product) => {
    //console.log(arguments)
    let { productTitle } = product.parentProduct
    let { categoryTitle } = product.parentProduct.parentCategory


    let slide = swiper?.slides[key];
    let isOpen = slide?.classList.contains('card-home-loan--open');



    // GTM tag fire
    gtmEvent({
      'event': 'moreInfoPopup',
      'productCategory': categoryTitle,
      'productType': productTitle
    })
    if (swiper) {
      //if (swiper && !isTabletDown() && !isOpen) {
      removeExpands();
      setMoreInfoIndex(key)
      setProductQuery(productTitle)
      openModal();
      return
      removeExpands();
      let slide = swiper.slides[key];
      setProductQuery(productTitle);

      slide.style.transition = 'all 0.4s';
      setTimeout(() => slide.classList.add('card-home-loan--open'), 50);
      if (window.innerWidth > 767) {
        slide.style.width = (slideWidth * 2 + 20) + 'px';
      }

      if ((key == swiper.slides.length - 1) && swiper.slides.length > 2) {
        swiper.params.slidesOffsetAfter = slideWidth;
        swiper.update();
        if (window.innerWidth > 767) {
          slide.style.width = (slideWidth * 2 + 20) + 'px';
        }
      }

      addTabIndex(slide, 0);
      swiper.slideTo(key, 400);
    } else if (swiper && isTabletDown()) {
      swiper.slideTo(key, 400);

      setMoreInfoIndex(key)
      setProductQuery(productTitle)
      setTimeout(() => openModal(), 100);
    }
  }

  const detectHiddenVariant = (productId) => {
    let isMatch = hideCalculationOnVariants.some((variant) => {
      return variant.id === productId ? true : false
    })
    return isMatch
  }

  useEffect(() => {
    if (!listingMounted && typeof window !== `undefined`) {
      let parsed = queryString.parse(location.search);
      parsed && parsed.product && setTimeout(() => animateScrollTo(listingRef.current.offsetTop, { speed: 2000 }), 400);
      listingMounted = true;
      checkQueryString()
    }
  }, [swiper])

  return (
    <>
      {/* This modal is for moreinfo */}
      <ReactModal
        className={`modal-product feature-product ${closeModalState ? 'close' : ''}`}
        overlayClassName={`modal-product__overlay ${closeModalState ? 'close' : ''}`}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={'Product card information'}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        ref={modalRef}
      >
        <div className="modal-product__content mobile-card">
          <button
            className="modal-product__close"
            onClick={closeModal}
          >
            <Close />
          </button>
          <HomeLoanCard
            ctaText={productCard[moreInfoIndex].ctaText}
            loanAmount={calculateLoan(loanAmount, productCard[moreInfoIndex].primaryRate)}
            product={productCard[moreInfoIndex]}
            expand={(index) => expand(index, productCard[moreInfoIndex])}
            removeExpands={removeExpands}
            hideCalculation={detectHiddenVariant(productCard[moreInfoIndex].id)}
            calcError={calcError}
            isExpanded={true}
          />
        </div>
      </ReactModal>
      {/* This modal is for moreinfo */}
      <section className={`listing-home-loan carousel-${currentUid}`} ref={listingRef}>
        <div className="outer-container">
          <div className="inner-container">
            <ModuleHeader
              classNames="listing-home-loan__header"
              auxtitle={auxtitle}
              title={title}
              subtitle={subtitle}
            />
            <section className="listing-home-loan__control">
              <div className="listing-home-loan__calc">
                <label
                  id="home-loan-calc-label"
                  htmlFor="home-loan-calc"
                  className="listing-home-loan__calc-label"
                >
                  Loan amount
                </label>
                <input
                  id="home-loan-calc"
                  autoComplete="off"
                  aria-labelledby="listing-home-loan-error"
                  className={`listing-home-loan__calc-input ${calcError ? 'error' : ''}`}
                  defaultValue={isDesktop() ? formatCurrency(cleanUpLoanOutput(defaultLoanAmount)) : null}
                  onChange={(e) => {
                    setLoanAmount(e.target.value)
                    handleLoanErrorMessage(e.target.value)
                    formatInputAmount(e.target.value)
                  }}
                  onBlur={(e) => {
                    checkInputDefault(e.target.value);
                    gtmEvent({
                      'event': 'loanAmountEntered',
                      'loanAmount': removeCurrencyFormat(e.target.value)
                    })
                  }
                  }
                  placeholder={`$${cleanUpLoanOutput(defaultLoanAmount)}`}
                  type="text"
                  ref={loanInput}
                />
                <p>For a 30 year loan term</p>
                <p
                  id="listing-home-loan-error"
                  className={`listing-home-loan__calc-error ${calcError ? 'active' : ''}`}
                  role="alert"
                  aria-hidden={calcError ? false : true}
                >
                  {calcError || ''}
                </p>
              </div>
              {isDesktop() ?
                <div className="listing-home-loan__nav">
                  <div id={`filters-pagination-${currentUid}`} className={`pagination-${currentUid}`}>
                    
                  </div>
                  <button
                    className={`listing-home-loan__nav-btn prev-${currentUid} ${swiper && slideIndex <= 1 ? 'inactive' : ''}`}
                    id={`filters-prev-${currentUid}`}

                    onClick={() => {
                      removeExpands()
                    }}
                  >
                    <Chevron className="reverse" />
                  </button>
                  <button
                    className={`listing-home-loan__nav-btn next-${currentUid}
                      ${swiper && swiper.slides && slideIndex >= swiper.slides.length - (isDesktop() ? 1 : 0) ? 'inactive' : ''}
                    `}
                    id={`filters-next-${currentUid}`}
                    onClick={() => {
                      removeExpands()
                    }}
                  >
                    <Chevron />
                  </button>
                </div>
                : null}
            </section>
            {enableSwiper ?
              <Swiper {...sliderConfig} getSwiper={setSwiper}>
                {productCard.map((product, index) => {

                  return <HomeLoanCard
                    ctaText={product.ctaText}
                    key={index}
                    loanAmount={calculateLoan(loanAmount, product.primaryRate)}
                    product={product}
                    expand={() => expand(index, product)}
                    removeExpands={removeExpands}
                    hideCalculation={detectHiddenVariant(product.id)}
                    calcError={calcError}
                  />
                })}
              </Swiper>
              : null}
            {isTablet() ?
              <div className={`listing-home-loan__nav pagination-${currentUid}`} id="filters-pagination"></div>
              : null}
          </div>
        </div>
      </section>
    </>
  )
}

export default ListingHomeLoan
