import * as React from 'react';
import AnimatedLink from '../global/animatedLink';
import ModuleHeader from './module-header';
import SmartLink from './../global/smart-link';

// ICONS
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg'

// Props
interface props {
  align: string
  auxtitle?: string
  title: string
  subtitle?: string
  linkText: string
  productLinkList: Array<ProductLink>
  cardSize: string
  alignment: string
  smartLink: any
}

interface ProductLink {
  title: string
  subtitle: string
  smartLink: any
  icon: {
    url: string
    title?: string
    alt?: string
  }
}

const ProductLink = ({title, subtitle, icon, smartLink}: ProductLink) => (
  <SmartLink className="product-link" data={smartLink}>
    <img className="product-link__icon" src={icon.url} alt={icon.alt}/>
    <div className="product-link__content">
      <p className="product-link__content-title">{title}</p>
      <p className="product-link__content-text">{subtitle}</p>
    </div>
    <span className="product-link__arrow">
      <ArrowRightBlue/>
    </span>
  </SmartLink>
)

const ProductFeatureBlock = ({auxtitle, title, subtitle,  linkText, productLinkList, align="left", smartLink}: props) => {
  return (
    <>
      <section className={`product-block product-block--${align}`}>
        <div className="outer-container">
          <div className="inner-container">
            <section className="product-block__callout">
              <ModuleHeader
                auxtitle={auxtitle}
                title={title}
                subtitle={subtitle}
                smartLink={smartLink}
                linkText={linkText}
              />
            </section>
            <section className="product-block__content">
              <ul className="product-block__content-list">
                {productLinkList.map(({title, subtitle,  icon, smartLink}: ProductLink, key) => (
                  <li className="product-block__content-item" key={key}>
                    {smartLink &&
                      <ProductLink
                        title={title}
                        subtitle={subtitle}
                        smartLink={smartLink}
                        icon={icon}
                      />
                    }
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProductFeatureBlock
