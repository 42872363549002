import * as React from 'react';
import { useEffect, useState, useRef, Fragment } from "react"
import queryString from 'query-string';
import setQueryString from 'set-query-string';
import animateScrollTo from 'animated-scroll-to';

// HELPERS
import { isTablet, isDesktop, isMobile } from '../../helpers/breakpoints'
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg'
// @ts-ignore
import Close from '../../svg/close-x.svg'

// Components
import ModuleHeader from '../modules/module-header';
import SmartLink from '../global/smart-link';
import FeaturedProduct from '../cards/card-featured-product';
import ReactModal from 'react-modal';
import gtmEvent from './../../helpers/gtmEvent';
import { filter } from 'lodash';

// Props
interface props {
  auxtitle?: string
  title: string
  information?: string
  subtitle?: string
  productCard: any
  filterTitle: string
  preselectedFilter: {
    categoryTitle: string
  }
  filters: [{
    categoryTitle: string
  }]
}

const ListingFeaturedProduct = ({
  auxtitle,
  title,
  subtitle,
  productCard,
  filterTitle,
  preselectedFilter,
  filters
  }: props) => {
  let listingMounted = false;
  const listingRef = useRef(null);
  const initQueryString = typeof window !== `undefined` ? queryString.parse(location.search) : null;
  const [filteredProductCards, setFilteredProductCards] = useState(
    productCard.filter((card) => initQueryString && initQueryString.filter ?
      card.parentProduct.parentCategory.categoryTitle === initQueryString.filter.replace(/\-+/g, ' ')
    : card.parentProduct.parentCategory.categoryTitle === preselectedFilter.categoryTitle)
  )
  const filteredCategory = filteredProductCards.length > 0 ? filteredProductCards[0].parentProduct.parentCategory : null;
  const modalRef = useRef(null);
  const [closeModalState, setCloseModalState] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [moreInfoIndex, setMoreInfoIndex] = useState(0);

  const closeModal = () => {
    setCloseModalState(true)

    setTimeout(() => {
      setCloseModalState(false)
      setIsOpen(false);
    }, 500);
  }

  const openModal = () =>  {
    setIsOpen(true);
  }

  const checkQueryString = () => {
    const parsed = queryString.parse(location.search);
    
    // filter=creditCard&product=productName
    if (parsed.filter) {
      setFilter(parsed.filter.replace(/\-+/g, ' '))
      setTimeout(() => animateScrollTo(listingRef.current.offsetTop, {speed: 2000}), 400);
    }

    if (parsed.product) {
      setTimeout(() => {
        // TODO: replace dashes to spaces
        let cleanedProduct = parsed.product.replace(/\-+/g, ' ');
        let matchedProductIndices = [];
        for (let i = 0; i < filteredProductCards.length; i++) {
          const card = filteredProductCards[i];
          if (card.parentProduct.productTitle === cleanedProduct) {
            matchedProductIndices.push(i);
          }
        }
        setTimeout(() => {
          let cardToHighlight = filteredProductCards[matchedProductIndices[0]];
          // Only expand more info if it has more info data
          if (cardToHighlight && cardToHighlight.parentProduct.moreInfo.length > 0) {
            moreInfoExpand(matchedProductIndices[0])
          }
          highlightCard(matchedProductIndices[0])
        }, 1500);
      }, 400);
    }
  }

  const setProductQuery = (productTitle, productFilter) => {
    const parsed = queryString.parse(location.search);
    
    setQueryString({
      filter: productFilter ? productFilter.replace(/\s+/g, '-') : parsed.filter ? parsed.filter : null,
      product: productTitle ? productTitle.replace(/\s+/g, '-') : null,
    })
  }
  
  const setFilter = (filter) => {
    // Check if the filter exists in the filter list first
    if (filters.filter(fil => fil.categoryTitle == filter).length > 0) {
      const filterButtons = document.querySelectorAll('.listing-featured-product__filters-button');
      const cards = document.querySelectorAll('.card-featured-product');
      // removes highlighted class on card on filter click
      cards.forEach(card => card.classList.remove('highlight'))

      filterButtons.forEach(filterButton => {
        filterButton.classList.remove('active');
        filterButton.classList.remove('highlight');
        if (filterButton.innerHTML == filter) {
          filterButton.classList.add('active');
        }
      });
      
      setFilteredProductCards(
        productCard.filter(card => {
          return card.parentProduct.parentCategory.categoryTitle === filter
        })
      );
      
      setTimeout(() => {
        setQueryString({
          filter: filter.replace(/\s+/g, '-'),
          product: null,
        })
      }, 50);
    }
  }
  
  const highlightCard = (index) => {
    const productCards = document.querySelectorAll('.card-featured-product');
    if (productCards[index]) {
      productCards[index].classList.add('highlight');
    }
  }

  const moreInfoExpand = (key) => {
    let { productTitle } = filteredProductCards[key].parentProduct
    let { categoryTitle } = filteredProductCards[key].parentProduct.parentCategory
  
    gtmEvent({
      'event': 'moreInfoPopup',
      'productCategory': categoryTitle,
      'productType': productTitle
    })
  
    setProductQuery(productTitle, categoryTitle), 
    setMoreInfoIndex(key)
    setTimeout(() => openModal(), 100);
  }

  useEffect(() => {
    if (!listingMounted) {
      listingMounted = true;
      // const { filter, product } = queryString.parse(location.search);
      checkQueryString()
    }
  }, [])

  return (
    <>
      {/* This modal is for moreinfo */}
      <ReactModal
        className={`modal-product feature-product ${closeModalState && 'close'}`}
        overlayClassName={`modal-product__overlay ${closeModalState && 'close'}`}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={'Product card information'}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        ref={modalRef}
      >
        <div className="modal-product__content mobile-card">
          <button
            className="modal-product__close"
            onClick={closeModal}
          >
            <Close />
          </button>
          <FeaturedProduct 
            product={filteredProductCards[moreInfoIndex]}
            isExpanded={true}
          />
        </div>
      </ReactModal>
      {/* This modal is for moreinfo */}
      <section className="listing-featured-product" ref={listingRef}>
        <div className="outer-container">
          <div className="inner-container">
            <ModuleHeader
              classNames="listing-featured-product__header"
              auxtitle={auxtitle}
              title={title}
              subtitle={subtitle}
            />
            <section className="listing-featured-product__control">
              {filterTitle &&
                <p className="listing-featured-product__filters-title">{filterTitle}</p>
              }
              <div className={`listing-featured-product__filters`}>
                <div className={`listing-featured-product__filters-buttons`}>
                  {filters.map(filter => (
                    <button 
                      className={`
                        listing-featured-product__filters-button 
                        ${preselectedFilter.categoryTitle === filter.categoryTitle ? 'active' : ''}
                      `} 
                      onClick={() => setFilter(filter.categoryTitle)} 
                      key={filter.categoryTitle}
                    >
                      {filter.categoryTitle}
                    </button>
                  ))}
                </div>
              </div>
            </section>
            <ul className={`
              listing-featured-product__list ${filteredProductCards.length > 2 ? 'three-col' : ''}
              ${filteredProductCards.filter((card) => card.hasCampaignLabel).length > 0 ? 'campaign' : ''}
            `}>
              {filteredProductCards.map((product, index) => (
                index < 3 ?
                  <li className={`card-featured-product ${product.hasCampaignLabel ? 'campaign' : ''}`} key={index}>
                    <FeaturedProduct
                      product={product}
                      moreInfoExpand={() => moreInfoExpand(index)}
                    />
                  </li>
                : null
              ))}
            </ul>
            <div className="listing-featured-product__view-all">
              {filteredCategory ?
                <SmartLink data={filteredCategory.categoryPageLink} className="listing-featured-product__view-all-link">
                  {filteredCategory.categoryPageLinkText ?
                    filteredCategory.categoryPageLinkText
                  : 
                    `View all ${filteredCategory.categoryTitle} products`
                  }
                  <ArrowRightBlue />
                </SmartLink>
              : null}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ListingFeaturedProduct


