import * as React from 'react'
import { useState, useEffect } from 'react'
import { isMobile } from '../../helpers/breakpoints'
import { debounce } from '../../helpers/debounce'
import Swiper from 'react-id-swiper'
import { parseHTML } from '../../helpers/parseHTML'
import { FluidObject, FixedObject } from 'gatsby-image';
import { MatchHeight } from 'js-match-height';

// Modules
import SmartLink from '../global/smart-link'
import IconCard from './icon-card'

// ICONS
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg'

// Props
interface props {
  data: {
    auxtitle?: string
    title?: string
    subtitle?: string
    linkText?: string 
    iconCardList: any
    columnCount: string
    smartLink?: any
  }
}

interface IconCard {
  image: {
    url: string
    title: string
    alt: string
    fluid: FluidObject
    fixed: FixedObject
  }
  title: string
  text: string
  linkText: string
  linkUrl: any
}

const IconCardList = ({data}: props) => {
  const {
    auxtitle,
    title,
    subtitle,
    smartLink,
    linkText,
    iconCardList,
    columnCount,
  } = data;

  let isMounted = false;
  const [deviceWidth, setDeviceWidthStatus] = useState({ 
    isMobileDevice: isMobile(),
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDeviceWidthStatus({ isMobileDevice: isMobile() })
    }, 500)
    if (!isMounted) isMounted = true;
    if (!isMobile()) {
      const matchHeight = new MatchHeight('.toggle-card');
    }
    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)
  })

  return <section className="icon-cards">
    <div className="outer-container">
      <div className="inner-container">
        <header className={`icon-cards__header ${smartLink ? 'icon-cards__header--has-cta' : null}`}>
          <div className="icon-cards__header-content">
            <p className="icon-cards__header-aux">{auxtitle}</p>
            <h2 className="icon-cards__header-title">{parseHTML(title)}</h2>
            {subtitle ? <p className="icon-cards__header-subtitle">{subtitle}</p> : null}
          </div>
          {!deviceWidth.isMobileDevice && smartLink && linkText ?
            <div className="icon-cards__cta">
              <SmartLink className="icon-cards__cta-link" data={smartLink}>
                {linkText} <ArrowRightBlue/>
              </SmartLink>
            </div>
          : null}
        </header>
        {deviceWidth.isMobileDevice ?
          renderCardCarousel(iconCardList, linkText, smartLink)
        :
          renderCardList(iconCardList, columnCount)
        }
      </div>
    </div>
  </section>
}

export default IconCardList;

const renderCardCarousel = (iconCardList, linkText, smartLink) => {
  const carouselClass = "swiper-container icon-cards__swiper"
  const hasCta = smartLink && linkText;

  const sliderConfig = {
    slidesPerView: 'auto',
    containerClass: carouselClass,
    freeMode: false,
    scrollbar: {
      el: '.swiper-scrollbar',
      hide: true
    },
    mousewheel: false,
    a11y: {
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide',
      enabled: true
    },
    rebuildOnUpdate: true
  }

  return <>
    <Swiper {...sliderConfig}>
        {iconCardList.map((data: IconCard, key) =>
          <div className="icon-cards__swiper-item" key={key}>
            <IconCard
              image={data.image}
              title={data.title}
              text={data.text}
              linkUrl={data.linkUrl}
              linkText={data.linkText}
            />
          </div> 
        )}
    </Swiper>
    {hasCta ? 
    <section className="icon-cards__cta">
      <SmartLink className="icon-cards__cta-link" data={smartLink}>
        {linkText} <ArrowRightBlue/>
      </SmartLink>
    </section>
    : null}
  </>
}

const renderCardList = (iconCardList, columnCount) =>
  <ul className={`icon-cards__list icon-cards__list--${columnCount}`}>
    {iconCardList.map((data: IconCard, key) =>
      <li className="icon-cards__list-item" key={key}>
        <IconCard
          image={data.image}
          title={data.title}
          text={data.text}
          linkUrl={data.linkUrl}
          linkText={data.linkText}
        />
      </li>
    )}
  </ul>