import * as React from 'react';
import { useState, useEffect } from 'react';
import { isMobile } from '../../helpers/breakpoints';
import { debounce } from '../../helpers/debounce';
import AnimatedLink from '../global/animatedLink';
import { FluidObject } from 'gatsby-image';
import Img from "gatsby-image/withIEPolyfill";
import Swiper from 'react-id-swiper'
import { parseHTML } from '../../helpers/parseHTML';
import stripHtml from "striptags";

// ICONS
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg'
import ModuleHeader from './module-header';
import SmartLink from '../global/smart-link';
import { treeParentRecursive, generateSlug } from './../../helpers/urlBuilder';

// Props
interface props {
  auxtitle?: string
  title: string
  subtitle?: string
  linkText: string
  articleLinkList: Array<ArticleLink>
  smartLink: any
}

interface ArticleLink {
  title: string
  subtitle: string
  linkText: string
  smartLink?: any
  isArticle?: boolean
  article?: any
  image: {
    url: string
    fluid: FluidObject
    title?: string
    alt?: string
  }
}

const ArticleLink = ({title, subtitle, linkText, image, smartLink, isArticle, article}: ArticleLink) => {
  if (isArticle) {
    let parents = [];
    treeParentRecursive(article, parents);
    let slug = generateSlug(article.slug, parents);
    return (
      <AnimatedLink to={slug} className="article-link">
        <Img
          className="article-link__image"
          fluid={image.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={image.alt}
          title={image.title}
        />
        <div className="article-link__content">
          <p className="article-link__content-title">{parseHTML(title)}</p>
          <p className="article-link__content-text">{subtitle}</p>
        </div>
        <span className="article-link__cta">
          {linkText}
          <span className="article-link__cta-arrow" aria-hidden>
            <ArrowRightBlue/>
          </span>
        </span>
      </AnimatedLink>
    )
  } else {
    return (
      <SmartLink className="article-link" data={smartLink}>
        <Img
          className="article-link__image"
          fluid={image.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={image.alt}
          title={image.title}
        />
        <div className="article-link__content">
          <p className="article-link__content-title">{parseHTML(title)}</p>
          <p className="article-link__content-text">{subtitle}</p>
        </div>
        <span className="article-link__cta">
          {linkText}
          <span className="article-link__cta-arrow" aria-hidden>
            <ArrowRightBlue/>
          </span>
        </span>
      </SmartLink>
    )
  }
}


const renderLinkCarousel = (articleLinkList) => {
  const carouselClass = 'swiper-container content-hub__hub-swiper';

  const sliderConfig = {
    slidesPerView: 'auto',
    containerClass: carouselClass,
    freeMode: false,
    speed: 600,
    scrollbar: {
      el: '.swiper-scrollbar',
      hide: true
    },
    mousewheel: false,
    a11y: {
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide',
      enabled: true
    },
    rebuildOnUpdate: true
  };

  return <Swiper {...sliderConfig} >
    {articleLinkList.map(({title, subtitle, linkText, image, smartLink, article}: ArticleLink, key) => (
      <div className="content-hub__hub-swiper-item" key={key}>
        {smartLink &&
          <ArticleLink
            title={title}
            subtitle={subtitle}
            smartLink={smartLink}
            linkText={linkText}
            image={image}
          />
        }
        {article &&
          <ArticleLink
            title={stripHtml(article.title)}
            subtitle={stripHtml(article.summaryText)}
            linkText={linkText}
            image={article.backgroundImage}
            isArticle={true}
            article={article}
          />
        }
      </div>
    ))}
  </Swiper>
}

const renderLinkList = (articleLinkList) => (
  <ul className="content-hub__hub-list">
    {articleLinkList.map(({title, subtitle, linkText, image, smartLink, article}: ArticleLink, key) => (
      <li className="content-hub__hub-list-item" key={key}>
        {smartLink &&
          <ArticleLink
            title={title}
            subtitle={subtitle}
            smartLink={smartLink}
            linkText={linkText}
            image={image}
          />
        }
        {article &&
          <ArticleLink
            title={stripHtml(article.title)}
            subtitle={stripHtml(article.summaryText)}
            linkText={linkText}
            image={article.backgroundImage}
            isArticle={true}
            article={article}
          />
        }
      </li>
    ))}
  </ul>
)

const FeaturedContentHub = ({auxtitle, title, subtitle, linkText, articleLinkList, smartLink}: props) => {
  const [deviceWidth, setDeviceWidthStatus] = useState({ 
    isMobileDevice: isMobile(),
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDeviceWidthStatus({ isMobileDevice: isMobile() })
    }, 500)
    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize);
  })
  
  return (
    <>
      <section className={`content-hub`}>
        <div className="outer-container">
          <div className="inner-container">
            <section className="content-hub__callout">
              <ModuleHeader
                auxtitle={auxtitle}
                title={title}
                subtitle={subtitle}
                smartLink={smartLink}
                linkText={linkText}
              />
            </section>
            <section className="content-hub__hub">
              {deviceWidth.isMobileDevice ?
                renderLinkCarousel(articleLinkList)
              :
                renderLinkList(articleLinkList)
              }
            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default FeaturedContentHub
